import React, { Component } from 'react';

export class NoticeCaution extends Component {

    GoNext() {
        window.location.href = '/ready/7';
    }

    render() {
        return (
            <div className="body">
                <div className="navi2"></div>
                <div className="contents_guide">
                    <div className="text_box">
                        <div className="title">Notice &amp; Caution <a href="#" className="right_btn"><img src="../Images/btn_kr_normal.png" onload="InitButtion(this, true);" onclick="OnClickKor();" /></a> </div>
                        <div className="notice">
                            <div className="box">
                                <p>	&lt;General Restriction&gt;</p>
                                <ul>
                                    <li><span className="color_blu">Check your identification :</span> name, date of birth confirmation </li>
                                    <li><span className="color_blu">Check your application information :</span>  license, subject, seat number confirmation </li>
                                    <li><span className="color_blu">Keep the time : </span> time for entering the test room, starting/finishing the test </li>
                                    <li><span className="color_blu">Check the device : </span> headset volume, recording volume</li>
                                    <li><span className="color_blu">Follow the administration control</span>
                                        <ul>
                                            <li>Use the assigned cabinet and sit at the designated seat</li>
                                            <li>Take off hat, mask or sunglasses and put on your headset</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p className="mt10"> &lt; Procedure for illegal behavior&gt;</p>
                                <ul>
                                    <li><span className="color_blu">Measures against cheating: </span>Test application will be cancelled, and applicant will be ordered to exit the test room</li>
                                    <li><span className="color_blu">Application restriction for the illegal behavior on the test :</span>
                                ①Disqualification  ② 2 years restriction for test application
                                (Aviation safety law clause 45 and enforcement regulation
                                clause 97)</li>
                                </ul>
                            </div>
                            <div className="box">
                                <p>
                                    &lt;Test Information&gt;
                        </p>
                                <ul>
                                    <li><span className="color_blu">Time Restriction : </span>Each question has the response time
                                (20sec~90sec), total test time is 50 minutes</li>
                                    <li> <span className="color_blu">Say again :</span> You can use "say again" once for every question
                                before the ping sound for the answer</li>
                                    <li><span className="color_blu"> Test Result :</span> Test results are available on the homepage 2 weeks after the test date</li>
                                </ul>
                                <ul className="mt10">
                                    <li><span className="color_blu">Monitoring the test :</span> All situations are recorded by the CCTV</li>
                                    <li><span className="color_blu">Appeal :</span> File the appeal within 90 days of the test result date.</li>
                                    <li><span className="color_blu">Emergency call :</span> Press the button on the upper-right of the
                                desk to talk with the administrator </li>
                                </ul>
                                <ul>
                                    <li className="mt10" style={{ background: 'none' }}><img src="./Images/img_impossible.png" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="btn_box">
                        <ul>
                            <li className="second">Next page in <span className="color_re" id="id_Second">&nbsp;</span> Seconds</li>
                            <li><img id="id_Next" src="../Images/btn_confirm_normal.png" onClick={this.GoNext} onload="InitButtion(this, false);" style={{ cursor: 'default' }} /></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default NoticeCaution;