if(!Date.now) Date.now = function now() { return new Date().getTime(); };
function GetTickCount() { return Date.now(); }

function __RGB__(r, g, b)
{
    return r | g << 8 | b << 16;
}
/*
function __GetTimeString__(nTimems)
{
    var sMin = parseInt(nTimems / 60000);
    if(sMin < 10) sMin = "0" + sMin;
    var sSec = parseInt(nTimems % 60000 / 1000);
    if(sSec < 10) sSec = "0" + sSec;
    return sMin + ":" + sSec;    
}
*/

function __Request__(sValuename)
{
    var rtnval;
    var nowAddress = unescape(window.location.href);
    var parameters = new Array();
    parameters = (nowAddress.slice(nowAddress.indexOf("?")+1,nowAddress.length)).split("&");
    for(var i = 0 ; i < parameters.length ; i++)
    {
        if(parameters[i].indexOf(sValuename) != -1)
        {
            rtnval = parameters[i].split("=")[1];
            if(rtnval == undefined || rtnval == null)
            {
                rtnval = "";
            }
            return rtnval;
        }
    }
}

function InitButtion(obj, bEnable)
{
    if(obj.init_load != true) 
    {
        obj.init_load = true;
        var sExt = obj.src.substr(obj.src.length - 3, 3);
        obj.File = obj.src.replace("normal." + sExt, "");
        obj.Ext = sExt;
        __EnableButtion__(obj, bEnable);
    }
}

function __EnableButtion__(obj, bEnable)
{
    obj.btn_enable = bEnable;
    if(bEnable)
    {
        if(obj.onmousemove == null) obj.onmousemove = function()   
                                        {   if(obj.IsCapture == 1) 
                                            { 
                                                var objPos = __GetObjPos__(obj);
                                                if(window.event.clientX >= objPos.x && window.event.clientX <= objPos.x + objPos.w && window.event.clientY >= objPos.y && window.event.clientY <= objPos.y + objPos.h)
                                                {
                                                    if(obj.src != obj.File + "down." + obj.Ext) obj.src = obj.File + "down." + obj.Ext;
                                                }
                                                else if(obj.src != obj.File + "hover." + obj.Ext) obj.src = obj.File + "hover." + obj.Ext;
                                            };
                                        };
        if(obj.onmouseover == null) obj.onmouseover = function()    
                                        {   if(obj.IsCapture != 1 && obj.src != obj.File + "hover." + obj.Ext) obj.src = obj.File + "hover." + obj.Ext; }
        if(obj.onmouseout == null) obj.onmouseout = function()     
                                        {   if(obj.src != obj.File + "normal." + obj.Ext) obj.src = obj.File + "normal." + obj.Ext; }
        if(obj.onmousedown == null) obj.onmousedown = function()    
                                        {   if(obj.src != obj.File + "down." + obj.Ext) obj.src = obj.File + "down." + obj.Ext; obj.setCapture(); obj.IsCapture = 1;}
        if(obj.onmouseup == null) obj.onmouseup = function()      
                                        {   if(obj.src != obj.File + "normal." + obj.Ext) obj.src = obj.File + "normal." + obj.Ext; obj.releaseCapture(); obj.IsCapture = 0; 
                                            var objPos = __GetObjPos__(obj);
                                            if (window.event.clientX >= objPos.x && window.event.clientX <= objPos.x + objPos.w && window.event.clientY >= objPos.y && window.event.clientY <= objPos.y + objPos.h) if(obj.src != obj.File + "hover." + obj.Ext) obj.src = obj.File + "hover." + obj.Ext;
                                        }
        if(obj.src == obj.File + "disable." + obj.Ext) obj.src = obj.File + "normal." + obj.Ext; 
    }
    else
    {
        obj.onmousemove = null;
        obj.onmouseover = null;
        obj.onmouseout = null;
        obj.onmousedown = null;
        obj.onmouseup = null; 
        obj.releaseCapture();
        if(obj.src != obj.File + "disable." + obj.Ext) obj.src = obj.File + "disable." + obj.Ext; 
    }
}

function __GetObjPos__(obj)
{
	var x = 0;
	var y = 0;
	var o = obj;
	
	var w = obj.offsetWidth;
	var h = obj.offsetHeight;
	if (obj.offsetParent) 
	{
		x = obj.offsetLeft;
		y = obj.offsetTop;
		while (obj = obj.offsetParent)
		{
			x += obj.offsetLeft;
			y += obj.offsetTop;
		}
	}
	return {x:x, y:y, w:w, h:h, o:o};
}

