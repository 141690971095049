import React, { Component } from 'react';
import Header from "../component/Header";
import '../assets/style.css';
import '../assets/question/TypeCommon.js';

class ExamEnd extends Component{
    constructor(props){
        super(props);


    }

    GoNext=()=>{
        window.opener = null;
        window.open('', '_self');
        window.close();
    }    
    render(){        
        document.querySelector('body').style='background-color: #4688c5';
        return(            
    <div>
    <Header />           
    <>
        <div className="Last_box">
        <img src="../../images/ico_mock.png"/>
        <h1>수고하셨습니다.</h1>
        <div style={{marginTop:20}}><span>테스트 결과는 약 2주 이내, [학습리포트] 메뉴에서 확인 가능합니다</span></div>
        
        <div className="btn_area">
        
            <button className="btn_close" id="" onClick={this.GoNext}>종료하기</button> 
        </div>
        </div>
    </>
    </div>
        )
    }

}
    export default ExamEnd;