import { ReactMic } from 'react-mic';
import React, { Component } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner'
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import AudioSpectrum from 'react-audio-spectrum';
class ReactMicCom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: false,
      _blob:null,
      dispSpinner:false,
      _bStop:false,
      _bPlay:false,
      _blobURL:null,
    }

    this.onData = this.onData.bind(this);
    this.onStop = this.onStop.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.recFileName = null;

  }
  getRandomString() {
    if (window.crypto && window.crypto.getRandomValues && navigator.userAgent.indexOf('Safari') === -1) {
        var a = window.crypto.getRandomValues(new Uint32Array(3)),
            token = '';
        for (var i = 0, l = a.length; i < l; i++) {
            token += a[i].toString(36);
        }
        return token;
    } else {
        return (Math.random() * new Date().getTime()).toString(36).replace(/\./g, '');
    }
}
  getFileName(fileExtension) {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var date = d.getDate();
    //return 'RecordRTC-' + year + month + date + '-' + this.getRandomString() + '.' + fileExtension;

    console.log('recording file:'+this.props.filename);
    this.recFileName=this.props.filename;
    return this.props.filename+".mp3";
}
  startRecording = () => {  
    let self = this;
    
    if(document.audio)
      document.audio.pause();

      this.setState({ 
        _bPlay:false
      });
      
      setTimeout(function() {        
        self.setState({ record: true});        
    }, 500);
  }
 
  stopRecording = () => {

     
    let self = this
      
    if(document.audio)
      document.audio.pause();  
    
    this.setState({ 
        _bPlay:false
      });

    setTimeout(function() {        
      self.setState({ record: false });    
    }, 500);

    
  }
 
  onData(recordedBlob) {
    console.log('chunk of real-time data is: ', recordedBlob);    
  }
 
  onStop=(recordedBlob)=>{
    console.log('recordedBlob is: ', recordedBlob);
    this.state._blob = recordedBlob.blob;
    this.state._blobURL = recordedBlob.blobURL;

    if(this.props.disptype=='Q')
      this.uploadFiles()
  }

  uploadFiles = () => {
        
    var blob = this.state._blob;
    var file = new File([this.state._blob], this.getFileName('mp3'), {
        type: 'audio/mp3'
    });

    //console.log('str='+src);


    //var options = new FileUploadOptions();
    
    //options.mimeType="multipart/form-data";
 
    var params = new Object();
    /*
    options.params = params;     
    var ft = new FileTransfer();
    console.log('upload start!!')
    */

    //spinnerplugin.show();

    var optionAxios = {
        headers: {
            //'Content-Type': 'application/x-www-form-urlencoded'
            'Content-Type': 'multipart/form-data'            
            //'Content-Type': 'access-control-request-method' 
            
        }
    }

    this.setState({
        dispSpinner:true
    })            

    let form = new FormData() 
    form.append('video-blob', file);
     
     axios.post("https://tic1.engpool.com/upload.cshtml", form) 
     .then( response => { 
         console.log('response : ', JSON.stringify(response, null, 2)) 
         console.log('저장완료')
          //alert('저장 완료했습니다..');
         this.setState({
            dispSpinner:false,
        })      
        if(this.props.disptype=='Q'){
          this.props.GoNext(); 
        }

        }
    )
     .catch( error => { 
        alert('저장에 실패했습니다. 관리자에게 문의주셔요');
        return;
        console.log('failed', error)
     })

}


playRecording = () => {
    
  if(this.state.record)
    this.stopRecording();
  else{
    //document.audio.play();       
    this.playRecAudio();
    this.setState({ 
      _bPlay:true
    });
  }      

}


playRecAudio = () =>{
  let self = this;

  if(self.state._blob.size<50000)
  {
    alert('녹음파일이 작습니다. 다시 녹음해주세요');
    return;
  }
  
  setTimeout(function() {
    //self.setState({ record: false });    
    if(!self.state._blob) return;
      //self.replaceAudio(URL.createObjectURL(self.state._blob));     
      self.replaceAudio(self.state._blobURL);     
      
          //document.audio.currentTime = 0;
          document.querySelector('#audio_rec').currentTime = 0;
      
          //if(!document.audio.paused) return;
          if(!document.querySelector('#audio_rec').paused) return;
          setTimeout(function() {
              //if(!document.audio.paused) return;
             // if(!document.querySelector('#audio_rec').paused) return;
            //document.audio.play();
              //document.audio.play()
              self.setState({
                _bPlay:true,
 
              })             
              self.audioEle1.play();
             
              
          }, 100);
          
          //document.audio.play();
          //self.audioEle1.play();
 
      }, 300);
    
  
}
replaceAudio=(src)=>{
    
 
 
  //var newAudio = document.querySelector('audio');
  var newAudio = document.querySelector('#audio_rec')
  newAudio.src = '';
  //var newAudio = document.createElement('audio');
  /*
  newAudio.controls = true;
  newAudio.autoplay = true;
  newAudio.id = 'audio_rec';
  newAudio.name = 'audio_rec';   
  */
  //this.recAudioFullUrl = "https://tic1.engpool.com/upload/" + this.getFileName('mp3');
  //this.recAudioFullUrl = this.state._blobURL
  this.recAudioFullUrl = src;
  //this.recAudioFullUrl = this.playlist[4];

  if(src){
      newAudio.src = this.recAudioFullUrl;       
  }
  /*
  var parentNode = document.querySelector('audio').parentNode;
  parentNode.innerHTML = '';
  parentNode.appendChild(newAudio);
  */
  //document.audio = newAudio;
  //this.audioEle1 = document.audio;
  this.audioEle1 = newAudio
   /*
  this.setState({
    audioEle:document.audio
  })
  */

  //this.state.audioEle.play();
  
}
  render() {
    if(this.state.dispSpinner){
      return(
          <div style={{textAlign: 'center'}}>
      <Spinner animation='border' role='status'>
      <span className='sr-only'>Loading...</span>
      </Spinner>
      </div>
      )

      
  }        
  let a_id = this.state.audioEle1 ? this.state.audioEle1.src : "";

  if(this.props.disptype=='Q'){
    return (
    <div>   
    
        <ReactMic
          record={this.state.record}
          width={this.props.width}
          className="sound-wave"
          onStop={this.onStop}
          onData={this.onData}
          strokeColor="#000000"
          backgroundColor="#dcdcdc" />
         
    </div>  
    );
  }
  else{
    return (
     
      <div>
          {
      this.state._bPlay === false ? (   
        <ReactMic
          record={this.state.record}
          width={this.props.width}
          className="sound-wave"
          onStop={this.onStop}
          onData={this.onData}
          strokeColor="#000000"
          backgroundColor="#ebebec" />)
          : (<AudioSpectrum
            id="audio-canvas"
            height={110}
            width={this.props.width}
            audioId={'audio_rec'}
            capColor={'red'}
            capHeight={2}
            meterWidth={10}
            meterCount={512}
            meterColor={[
              {stop: 1, color: 'black'}
            ]}  
            gap={4}
          />)}
        
        <div style={{marginTop:20}}>
        <Button id="btn-start-recording" variant={!this.state.record ?  'primary':'danger' }  size="lg" onClick={this.startRecording} >Record</Button>
        <Button id="btn-stop-recording" variant={!this.state.record ?  (this.state._bPlay ? 'default': 'primary') :'primary' } size="lg" onClick={this.stopRecording} >Stop</Button>
        <Button id="btn-stop-recording" variant={this.state._bPlay ?  'danger': 'primary'} size="lg" onClick={this.playRecording} >{this.state._bPlay ? 'Playing': 'Play'}</Button> 
        {/* <Button id="btn-upload-recording" variant="primary" size="lg"onClick={this.uploadFiles} >Upload</Button> */}
        
        <div><audio id="audio_rec" crossorigin="anonymous" preload="true" controls src={a_id}></audio></div>
        </div>  
      </div>
        
    );
  }
  }
}export default ReactMicCom;