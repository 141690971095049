import React from 'react';

const NoticeCautionKor = (props) => {
    const webtoon = props.webtoon;
    return (
        <div className="body">
        <div className="navi2"></div>
        <div className="contents_guide"> 
            <div className="text_box">
                <div className="title">Notice &amp; Caution <a href="#" className="right_btn"><img src="../Images/btn_en_normal.png" onload="InitButtion(this, true);" onclick="OnClickEng();"/></a> </div>
                <div className="notice_kr">
                    <div className="box">
                        <p> &lt;항공영어구술능력증명시험 응시자의 의무사항 안내&gt;</p>
                        <ul>
                            <li><span className="color_blu">신원정보를 확인 : </span> 이름, 생년월일 </li>
                            <li><span className="color_blu">응시정보 확인 : </span> 자격, 과목, 좌석번호 </li>
                            <li><span className="color_blu">시간 준수 : </span>입실시간, 시험 시작/종료 시간</li>
                            <li><span className="color_blu">장비 확인 :</span> 헤드폰 음량, 녹음 </li>
                            <li><span className="color_blu">부정행위 금지</span> </li>
                            <li><span className="color_blu">관리자 통제 협조 :</span>
                                <ul>
                                    <li>지정된 사물함을 사용하고 지정된 좌석에 착석해 주세요.</li>
                                    <li>헤드셋을 착용하고 모자, 마스크 또는 선글라스를 벗어주세요.</li>
                                </ul>
                            </li>
                        </ul>
                        <p className="mt10">&lt;항공영어구술능력증명시험 응시 주의 및 금지사항 안내&gt; </p>
                        <ul>
                            <li><span className="color_blu">부정행위에 대한 조치 :</span>시험 무효처리 되고, 응시자는 퇴장 명령을 받습니다.</li>
                            <li> <span className="color_blu">부정행위에 대한 응시 제한 :</span>
                                 ①자격박탈 ②2년간 시험 응시 제한(항공안전법 제45조 및 시행규칙 제97조)</li>
                        </ul>
                    </div>
                    <div className="box">
                        <p><span className="mt10">&lt; 항공영어구술능력증명시험 응시 정보사항 안내&gt;</span></p>
                        <ul>
                            <li><span className="color_blu">시간제한 :</span> 각 문항은 응답시간(20 초 ~ 90 초)이 있으며, 총 시험 시간은 50 분입니다. </li>
                            <li><span className="color_blu">Say again : </span>"삐" 소리가 들리기 전에 모든 질문에 대해 "Say again"을 한번 사용할 수 있습니다. </li>
                            <li> <span className="color_blu">시험결과 : </span>시험결과는 2주 후 홈페이지에서 확인 할 수 있습니다. </li>
                            <li><span className="color_blu">시험감시 :</span> 모든 상황은 CCTV에 기록 됩니다. </li>
                            <li><span className="color_blu"> 이의신청 :</span> 성적발표 후 90일이내에 이의제기를 신청할 수 있습니다.</li>
                            <li><span className="color_blu"> 긴급요청 : </span>비상 상황일 때, 책상 오른쪽 상단에 있는 버튼을 누르면 관리자를 호출 할 수 있습니다.</li>
                        </ul>
                        <ul>
                            <li className="mt10" style={{background:'none'}}><img src="../Images/img_impossible.png"/></li>
                        </ul>                    
                    </div>
                </div>
            </div>
            <div className="btn_box">
                <ul>
                    <li className="second">Next page in <span className="color_re" id="id_Second">&nbsp;</span> Seconds</li>
                    <li><img id="id_Next" src="../Images/btn_confirm_normal.png" onload="InitButtion(this, false);" style={{cursor:'default'}}/> </li>
                </ul>
            </div>
        </div>
    </div>
    )
}

export default NoticeCautionKor;