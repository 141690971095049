import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import Main from "./container/Main";
import QuestionHome from "./container/QuestionHome";
import ExamEnd from "./component/ExamEnd";
import RecordRTCTest from "./component/reactRTC";
import ReactMicCom from "./component/ReactMic";
import Common from './assets/Common.js';
import jQuery from "jquery";

import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

//import importScript from './component/importScript';
window.$ = window.jQuery = jQuery;


class App extends Component {  
  static displayName = App.name;
  static u_id = null;  
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

   
  constructor(props){
    super(props);

    const { cookies } = props;

    this.state = {
        fshow:false,
        users:null,
        util:null,
        ui:null,
        xhr:null,
        uInfo: cookies.get('EPTA'),
    };

    
}
  /*
  componentDidMount(){
    importScript("./assets/js/ui.js");
  }
  */
 appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);

    }

 componentDidMount(){
   
  if(this.state.uInfo&& this.state.uInfo!=='undefined'){
    this.u_id = this.state.uInfo.split('&')[1].split('=')[1];
    this.u_no = this.state.uInfo.split('&')[0].split('=')[1];
    this.u_name = this.state.uInfo.split('&')[2].split('=')[1];
  }

  /*
  this.appendScript("/assets/js/util.js");
  this.appendScript("/assets/js/users.js");
  this.appendScript("/assets/js/ui.js");
  this.appendScript("/assets/js/xhr.js");
  */
  

  const script = document.createElement("script");
    script.src = "/assets/js/util.js";
    script.async = true;
    
    document.body.appendChild(script);
    
    const script1 = document.createElement("script");
    script1.src = "/assets/js/users.js";
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "/assets/js/ui.js";
    script2.async = true;
    document.body.appendChild(script2);

    const script3 = document.createElement("script");
    script3.src = "/assets/js/xhr.js";
    script3.async = true;
    document.body.appendChild(script3);

    script3.onload = () => this.scriptLoaded();
     
  
}

scriptLoaded() {
  
    // this.u_no = "100009"
    // this.u_id = "test8"
    // this.u_name = "테스트"
    let self = this;

      console.log('login check')
      setTimeout(function(){   
        if(self.u_no){
          window.users.setUserno(self.u_no);
          window.users.setUserid(self.u_id);
          window.users.setUserName(self.u_name);  
          console.log("login check complete");
        }
        else{
          if(window.users.userno && window.users.userno==-1){
            alert('로그인 정보가 없습니다.');
            window.opener = null;
            window.open('', '_self');
            window.close();
            return;
          }
        }

        self.setState({  
          users: window.users,
          util: window.util,
          ui:window.ui,
          xhr:window.xhr,  
          fshow:true
        })
      }, 1000);  
  

}
  render () {
    if(!this.state.fshow){
      return(<div>loading</div>);
    }  

    return (
        <Router >
            <div>
            
                <Route exact={true} path="/:courseno" component={Main} />
                <Route exact={true} path="/ready/:stepno/:courseno" component={Main} />                
                <Route exact={true} path="/test/" component={QuestionHome} />            
                <Route exact={true} path="/test/:setno/:seqno" component={QuestionHome} />
                <Route exact={true} path="/examend/:courseno/:stime" component={ExamEnd} />
                <Route exact={true} path="/RTC" component={RecordRTCTest} />                     
                <Route exact={true} path="/rtcmic" component={ReactMicCom} />    
                 
            </div>
        </Router>
    );
  }
}

export default withCookies(App);