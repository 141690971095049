import React from 'react';
const idStep1 = (props) => {
    
    return (
    <div className="quiz_contents">
        <div id="id_Question" className="descript_box">&nbsp;{props.questions.QueQuestion}</div>
        <div className="qust_box">
            <div id="id_Listen" className="headset_box" style={{cursor:'hand'}}>
                <div id="id_Headset" className="box_top headset">&nbsp;</div>
                <div id="id_SayAgain" className="box_bottom">
                    <p></p>
                </div>
            </div>
            <div id="id_Response" className="mic_box disabled">
                <div className="box_top mic"> </div>
                <div className="box_bottom"><span className="img"></span>
                    <div className="progress_box2">
                        <div className="active">
                            <div id="id_ProgressRec" className="progress2" style={{backgroundposition: '-200px'}}> 
                                {/*<!-- -200px > 0% , 0px > 100% 입니다. -->*/}
                                <div className="progress_cover2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default idStep1;