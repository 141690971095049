import React from 'react';
import RecordRTC from 'recordrtc'; 
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner'
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import AudioAnalyser from './AudioAnalyser';


class RecordRTCTest extends React.Component 
{
    constructor(props){
        super(props);
     this.state={ 
         recorder: null,
         video: null,
         microphone: null,
         isEdge:null,
         isSafari: null,
         btnStartRecording:null,
         btnStopRecording: null,
         btnReleaseMicrophone: null,
         btnDownloadRecording: null,
         recordedChunks:null,
         recordedChunks:[],
         dispSpinner:false,
         recAudioStream:null

     } 
     /*

     */
        if(this.props.disptype=='T'){
            //this.stopRecordingCallback = this.stopRecordingCallback.bind(this);
            this.replaceAudio = this.replaceAudio.bind(this);
            this.btnStartRecording = this.btnStartRecording.bind(this);
     
            this.captureMicrophone = this.captureMicrophone.bind(this);     
            
            this.btnReleaseMicrophone  = this.btnReleaseMicrophone.bind(this);
        }
     
    }

     componentDidMount(){
        this.init();
  
    }     

     init(){         
      
        this.state.isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
        this.state.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        this.state.btnStartRecording = document.getElementById('btn-start-recording');
        this.state.btnStopRecording = document.getElementById('btn-stop-recording');
        this.state.btnReleaseMicrophone = document.querySelector('#btn-release-microphone');
        this.state.btnDownloadRecording = document.getElementById('btn-download-recording');
        
     }

     btnStartRecording(e) {
        
        this.state.btnStartRecording.disabled = true;
        this.state.btnStartRecording.style.border = '';
        this.state.btnStopRecording.style.fontSize = '';
        
            var self = this;
            if (!this.state.microphone) {
                this.captureMicrophone(function(mic) {
                    
                    self.setState({ microphone: mic });
                    //self.state.microphone = mic;
        
                    if(self.state.isSafari) {
                        self.replaceAudio();
        
                        document.audio.muted = true;
                        document.audio.srcObject = this.sate.microphone;
        
                        self.state.btnStartRecording.disabled = false;
                        self.state.btnStartRecording.style.border = '1px solid red';
                        self.state.btnStartRecording.style.fontSize = '150%';
        
                        alert('Please click startRecording button again. First time we tried to access your microphone. Now we will record it.');
                        return;
                    }
        
                    self.click(self.state.btnStartRecording);
                    //self.state.showVisualize(self.state.microphone)
                    

                });
                return;
            }
    
        this.replaceAudio(this);
    
        document.audio.muted = true;
        document.audio.srcObject = this.state.microphone;
    
        var options = {
            type: 'audio',
            numberOfAudioChannels: this.state.isEdge ? 1 : 2,
            checkForInactiveTracks: true,
            bufferSize: 16384
        };
    
        if(this.state.isSafari || this.state.isEdge) {
            options.recorderType = this.state.StereoAudioRecorder;
        }
    
        if(navigator.platform && navigator.platform.toString().toLowerCase().indexOf('win') === -1) {
            options.sampleRate = 48000; // or 44100 or remove this line for default
        }
    
        if(this.state.isSafari) {
            options.sampleRate = 44100;
            options.bufferSize = 4096;
            options.numberOfAudioChannels = 2;
        }
    
        if(this.state.recorder) {
            this.state.recorder.destroy();
            this.state.recorder = null;
        }
    
        this.state.recorder = RecordRTC(this.state.microphone, options);
    
        this.state.recorder.startRecording();
    
        
        this.state.btnStopRecording.disabled = false;
        //this.state.btnDownloadRecording.disabled = true;
        //this.props.showVisualize(this.props.pclass,this.state.microphone);
        
    };

     stopRecordingCallback=()=>{
      
        if(!this.state.recorder || !this.state.recorder.getBlob()) return;
        this.replaceAudio(URL.createObjectURL(this.state.recorder.getBlob()));       
        this.state.btnStartRecording.disabled = false;
        document.audio.muted = false;
        document.audio.currentTime = 0;
        
        
        setTimeout(function() {
            if(!document.audio.paused) return;
    
            setTimeout(function() {
                if(!document.audio.paused) return;
                document.audio.play();
            }, 1000);
               
           
            
            document.audio.play();
        }, 300);
      
        
        
        document.audio.play();

    
//        this.state.btnDownloadRecording.disabled = false;
    
        
        if(this.state.isSafari) {
        //    this.click(this.state.btnReleaseMicrophone);
        }
    }
     replaceAudio(src) {
        var newAudio = document.createElement('audio');
        newAudio.controls = true;
        newAudio.autoplay = true;
    
        if(src) {
            newAudio.src = src;
        }
        
        var parentNode = document.querySelector('audio').parentNode;
        parentNode.innerHTML = '';
        parentNode.appendChild(newAudio);
        document.audio = newAudio;
    }
     captureMicrophone(callback) {
       
        //this.state.btnReleaseMicrophone.disabled = false;
    
        if(this.state.microphone) {
            callback(this.state.microphone);
            return;
        }
    
        if(typeof navigator.mediaDevices === 'undefined' || !navigator.mediaDevices.getUserMedia) {
            alert('This browser does not supports WebRTC getUserMedia API.');
    
            if(!!navigator.getUserMedia) {
                alert('This browser seems supporting deprecated getUserMedia API.');
            }
        }
    
        navigator.mediaDevices.getUserMedia({
            audio: this.state.isEdge ? true : {
                echoCancellation: false
            }
        }).then(function(mic) {
            callback(mic);
            console.log(mic);
            
        }).catch(function(error) {
            alert('Unable to capture your microphone. Please check console logs.');
            console.error(error);
        });        

        

    }


    btnStopRecording=()=>{        
        this.state.btnStopRecording.disabled = true;        
        this.state.recorder.stopRecording(this.stopRecordingCallback());       

        if(this.props.disptype!='T'){
            this.uploadFiles();
        }
    }
    
    btnReleaseMicrophone() {
        this.disabled = true;
        this.disabled = false;
    
        if(this.state.microphone) {
            this.state.microphone.stop();
            this.state.microphone = null;
        }
    
        if(this.state.recorder) {
             this.click(this.state.btnStopRecording);
        }
    }
    
    btnDownloadRecording() {
        this.disabled = true;
        if(!this.state.recorder || !this.state.recorder.getBlob()) return;
    
        if(this.state.isSafari) {
            this.state.recorder.getDataURL(function(dataURL) {
                this.state.SaveToDisk(dataURL, this.getFileName('mp3'));
            });
            return;
        }
    
        var blob = this.state.recorder.getBlob();
        var file = new File([blob], this.getFileName('mp3'), {
            type: 'audio/mp3'
        });
        //this.state.recordedChunks.push(file);
        
        RecordRTC.invokeSaveAsDialog(file);      
        
    }
    
    click(el) {
        el.disabled = false; // make sure that element is not disabled
        var evt = document.createEvent('Event');
        evt.initEvent('click', true, true);
        el.dispatchEvent(evt);
    }
    
    getRandomString() {
        if (window.crypto && window.crypto.getRandomValues && navigator.userAgent.indexOf('Safari') === -1) {
            var a = window.crypto.getRandomValues(new Uint32Array(3)),
                token = '';
            for (var i = 0, l = a.length; i < l; i++) {
                token += a[i].toString(36);
            }
            return token;
        } else {
            return (Math.random() * new Date().getTime()).toString(36).replace(/\./g, '');
        }
    }
    
    getFileName(fileExtension) {
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var date = d.getDate();
        //return 'RecordRTC-' + year + month + date + '-' + this.getRandomString() + '.' + fileExtension;

        return this.props.filename+".mp3";
    }
    
    SaveToDisk(fileURL, fileName) {
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.download = fileName || 'unknown';
            save.style = 'display:none;opacity:0;color:transparent;';
            (document.body || document.documentElement).appendChild(save);
    
            if (typeof save.click === 'function') {
                save.click();
            } else {
                save.target = '_blank';
                var event = document.createEvent('Event');
                event.initEvent('click', true, true);
                save.dispatchEvent(event);
            }
    
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
    
        // for IE
        else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
    }

     onRecord = () => {
         var self = this;
          navigator .mediaDevices .getUserMedia({ audio: true, video: false })
           .then(function(camera) { self.readyCamera(camera); }) 
           .catch(function(error) { console.error(error); }); }
           
    readyCamera = (camera) => { 
        const video = document.getElementById('test-video');
         video.muted = false; video.volume = 0; 
         video.srcObject = camera; 
         const recorder = new RecordRTC(camera, { type: 'audio', }); 
         recorder.startRecoding(); 
         recorder.camera = camera; 
         this.setState({ recorder });
         } 
         
    onStop = () => { 
        const { recorder } = this.state;
        const video = document.getElementById('test-video'); 
        video.src = video.srcObject = null;
         video.muted = false; video.volume = 1; 
         video.src = URL.createObjectUrl(recorder.getBlob); 
         // 카메라 정지 recorder.camera.stop(); // 서버 저장 등을 위해 영상object 데이터 저장 this.keepVideo(recorder); this.setState({ recorder: null }); } keepVideo = (data) => { this.setState({ video: data.blob, src: URL.createObjecetUrl(data.getBlob()), }); } render() { return ( <div className='video-test-container'> { recorder ? <p>녹화중이에요~~</p> } <video id='test-video' autoPlay playsInline controls /> <button onClick={this.onRecord}>촬영하기<button/> <button onClick={this.onStop}>중지하기<button/> </div> ) } }
         this.keepVideo(recorder); 
         this.setState({ recorder: null });
          } 
          
    keepVideo = (data) => { 
        this.setState({ audio: data.blob, src: URL.createObjecetUrl(data.getBlob()), }); 
    }

    uploadFiles = () => {
        
        var blob = this.state.recorder.getBlob();
        var file = new File([blob], this.getFileName('mp3'), {
            type: 'audio/mp3'
        });

        //console.log('str='+src);


        //var options = new FileUploadOptions();
        
        //options.mimeType="multipart/form-data";
     
        var params = new Object();
        /*
        options.params = params;     
        var ft = new FileTransfer();
        console.log('upload start!!')
        */

        //spinnerplugin.show();

        var optionAxios = {
            headers: {
                //'Content-Type': 'application/x-www-form-urlencoded'
                'Content-Type': 'multipart/form-data'            
                //'Content-Type': 'access-control-request-method' 
                
            }
        }

        this.setState({
            dispSpinner:true
        })            

        let form = new FormData() 
        form.append('video-blob', file);
         
         axios.post("http://tic1.engpool.com/upload.cshtml", form) 
         .then( response => { 
             console.log('response : ', JSON.stringify(response, null, 2)) 
             console.log('저장완료')
             //alert('저장 완료했습니다..');
             this.setState({
                dispSpinner:false,
            })      
            }
        )
         .catch( error => { 
            alert('문항 저장에 실패했습니다.');
            return;
            console.log('failed', error)
         })

    }
        
    render() { 

        if(this.state.dispSpinner){
            return(
                <div style={{textAlign: 'center'}}>
            <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
            </Spinner>
            </div>
            )
        }        

        
        //this.state.microphone ? <AudioAnalyser audio={this.state.microphone} /> : '',document.getElementById('dispVisual');
        

        return (         
           
            <div>     
                {/*this.state.microphone ? <AudioAnalyser audio={this.state.microphone} /> : ''*/}

            <Button id="btn-start-recording" variant="danger" size="lg" onClick={()=>this.btnStartRecording()}  >Start Recording</Button>
            <Button id="btn-stop-recording"  variant="primary" size="lg" onClick={()=>this.btnStopRecording()} >Stop Recording</Button>
            <Button id="btn-release-microphone"  className={this.props.disptype!='T'? 'hidden' :'' } variant="primary" size="lg" onClick={()=>this.btnReleaseMicrophone()} >Release Microphone</Button>
            <Button id="btn-download-recording" className={this.props.disptype!='T'? 'hidden' :'' } variant="primary" size="lg" onClick={()=>this.btnDownloadRecording()} >Download</Button>
            <Button id="btn-upload-recording" className={this.props.disptype!='T'? 'hidden' :'' } variant="primary" size="lg" onClick={()=>this.uploadFiles()} >Upload</Button>            
            <hr/>
            <div><audio controls autoPlay  hidden={this.props.disptype!='T'?  'hidden':'' }></audio></div>

                 <hr/>
{/*
            
            {this.state.recorder ? <p>녹화중이에요~~</p> : <p></p> } 
            <audio id='test-video' autoPlay playsInline controls /> 
            <button onClick={this.onRecord}>촬영하기</button> 
            <button onClick={this.onStop}>중지하기</button>
*/}            
            </div> 
          
         ) 
    };
}
export default RecordRTCTest;