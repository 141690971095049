import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class UsageProgram extends Component{
    constructor(props){
        super(props);

    }


    GoNext=()=>{
        this.props.history.push('/ready/9/'+this.props.courseno);
    }

    render(){
    return (
        <div className="body">
        
        <div className="contents_guide1">         
            <div className="text_box">
                <div className="title">How to use program tool</div>
                <div className="basic_type">
                <div className="pro_info"><img src="../images/program_info.png" width="800"/></div>
                </div>
                <div className="btn_box">
                <ul>
                    <li className="second">Next page in <span className="color_re" id="id_Second">&nbsp;</span> Seconds</li>
                        <li><img src="../images/btn_confirm_normal.png" onload="InitButtion(this, true);" onClick={this.GoNext}/></li>
                </ul>
                </div>
            </div>
            
        </div>
    </div>
    )
    }
}
export default UsageProgram;