import React from 'react';
import { Link } from 'react-router-dom';


const idStep1 = (props) => {

      return (
            <div className="body">
                <div className="navi"> </div>
                <div className="contents_guide1">                                 
                <div className="volume">
                        <div className="volume_btn"> <img src="../images/btn_volume_normal.png" onclick="OnVolumeSliderShow();" onload="InitButtion(this, true);" alt=""/> </div>
                        <div id="id_Slider_Volume" style={{left : '-49px', top:'47px', width: '51px', height:'134px', position:'absolute', display: 'none', zindex:'999', background : '../Images/volume_control_bg.gif'}} onclick="event.cancelBubble = true;">
                        <iframe id="id_VolumeCtrl" style={{margintop:'9px',width:'100%',height:'124px'}} src="./Images/VolumeCtrl.htm" frameborder="0"></iframe> 
                        </div>
                         </div>                
                    <div className="text_box">
                        <div className="title">Identification – Step 1</div>
                        <div className="seat" id="id_SeatNo">&nbsp;</div>
                        <div className="headset"> <span className="en32 bold">Please, Put on your headset!</span><br/>
                            <span className="kr24">(헤드셋을 착용하세요!) </span> </div>
                    </div>
                    <div className="btn_only"><Link to="/ready/3"><img src="./Images/btn_continue_normal.png" onload="InitButtion(this, true);" alt=""/></Link> </div>
                </div>
            </div>
    )
}

export default idStep1;