import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Preparation extends Component {    
    constructor(props){
        super(props);

    }

    GoNext=()=>{
        this.props.history.push('/test/'+this.props.courseno+'/1');
    }    
    render(){
    return (
        <div className="body">        
        <div className="contents_guide1"> 
            {/*
            <div className="volume">
                <div className="volume_btn"> <img src="../images/btn_volume_normal.png" onclick="OnVolumeSliderShow();" onload="InitButtion(this, true);"/> </div>
                <div id="id_Slider_Volume" style={{left:'-49px', top:'47px', width: '51px', height:'134px', position:'absolute', display: 'none', zindex:'999', background : '../QuestionHtml/Images/volume_control_bg.gif'}} onclick="event.cancelBubble = true;">
                    <iframe id="id_VolumeCtrl" style={{margintop:'9px',width:'100%',height:'124px'}} src="../QuestionHtml/VolumeCtrl.htm" frameborder="0"></iframe>
                </div>
            </div>   
            */}

            <div className="text_box">
                <div className="title">Preparation <a href="#" className="right_btn" style={{cursor:"default"}}><img src="../Images/btn_demo.png"/></a> </div>                
                <div className="basic_type">
                    <ul className="preparation">
                        <li>
                            <p>Time Restriction : Each question has 20~90 seconds restriction and total test time is 50 minutes.</p>
                            시간제한 : 각 문항은 응답시간(20초 ~ 90초) 제한이 있으며 총 시험시간은 50분 입니다.</li>
                        <li>
                            <p>Answering will be recorded <span className="color_re">successfully only after the 'ping' sound.</span></p>
                            (질문이 끝나고 핑(ping) 소리 이후 답변하셔야 녹음이 시작됩니다.)</li>
                        <li>
                            <p>Say again is available only once before the 'ping' sound for recording.</p>
                            ( 녹음을 시작을 알리는 핑(ping) 소리 전까지만 Say again 기능을 한번 사용 가능합니다.)</li>
                        <li>
                            <p>Press the button on the desk when the system error occur. </p>
                            (시험 중 시스템에러가 발생하면 즉시 책상위 버튼을 누르시기 바랍니다.) 
                            <p>Don't touch anything and leave the screen as it is. </p>
                            (아무것도 조작하지 마시고 컴퓨터 화면 그대로 두시기 바랍니다.)</li>
                        <li>
                            <p> After the test, objection about the system error is unable.</p>
                            (시험 이후는 시스템에 대한 이의제기가 불가능합니다.)</li>
                    </ul>
                </div>
                <div className="btn_box">
                <ul>
                    <li className="second">Next page in <span className="color_re" id="id_Second">&nbsp;</span> Seconds</li>
                        <li><img src="../Images/btn_start_normal.png" onload="InitButtion(this, true);" onClick={this.GoNext}/></li>
                </ul>
            </div>
            </div>
            
        </div>
    </div>
    )
    }
}

export default Preparation;