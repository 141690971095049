import React, { Component } from 'react';
import axios from 'axios';

import Header from "../component/Header";
import SeatInfo from "../component/SeatInfo";
import IdStep1 from "../component/idStep1";
import IdStep2 from "../component/idStep2";
import IdStep3 from "../component/idStep3";
import NoticeCaution from "../component/NoticeCaution";
import NoticeCautionKor from "../component/NoticeCautionKor";
import VolumeRecordingTest from '../component/volumeRecordingTest';
import UsageProgram from '../component/UsageProgram';
import Qtypeinfo from '../component/QtypeInfo';
import Preparation from '../component/Preparation';


class Main extends Component{
    constructor(props){
        super(props);

        this.state = {
            stepNo : parseInt(props.match.params.stepno, 10),
            _courseno : parseInt(props.match.params.courseno, 10),
            uNo : parseInt(props.match.params.uno, 10)
        };

    }
    componentDidMount(){
       // this._getList();
        console.log("3333");      
    }
    componentWillReceiveProps(nextProps) {
        //요일이 바뀌면 다시 setState 처리
        console.log("444");

        if (this.state.stepNo &&  this.state.stepNo !== parseInt(nextProps.match.params.stepno, 10)) {
            this.setState({
                stepNo: parseInt(nextProps.match.params.stepno, 10),
                _courseno : parseInt(nextProps.match.params.courseno, 10)
            });
        }

    }

    componentDidUpdate(prevProps){
        //요일이 바뀌면 다시 setState 처리
        console.log("444");        
   
    }

    _getList(){
        //webtoon_list를 가지고 옵니다.
        const apiUrl = '/dummy/webtoon_list.json';
        
        axios.get(apiUrl)
            .then(data => {
                //가지고 온 리스트를 state에 저장합니다.
                this.setState({
                    webtoonList : data.data.webtoonList
                });
            })
            .catch(error => {
                console.log(error);
            });
    }


    render(){
        const _stepNo = this.state.stepNo;
        return (
            
            <div>
                <Header />  
        
                { _stepNo===1 ? ( 
                <SeatInfo />   
                ) : _stepNo===2 ? (
                    <IdStep1/> 
                ) : _stepNo===3 ? (
                    <IdStep2/>                     
                ) : _stepNo===4 ? (
                    <IdStep3/>                                         
                ) : _stepNo===5 ? (
                    <NoticeCaution />                                                              
                ) : _stepNo===6 ? (
                    <NoticeCautionKor />        
                ) : _stepNo===7 ? (
                    <VolumeRecordingTest courseno={this.state._courseno} history={this.props.history}/>                      
                ) : _stepNo===8 ? (
                    <UsageProgram courseno={this.state._courseno} history={this.props.history}/>
                ) : _stepNo===9 ? (
                    <Qtypeinfo courseno={this.state._courseno} history={this.props.history}/>                    
                ) : _stepNo===10 ? (
                    <Preparation courseno={this.state._courseno} history={this.props.history}/>                                        
                ) : (
                    <SeatInfo courseno={this.state._courseno} history={this.props.history}/>
                )}                
                
            </div>
        )
    }
}

export default Main;