import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactSWFCompat from 'react-swf/compat';
import axios from 'axios';

class SeatInfo extends Component {    
    constructor(props){
        super(props);
        this.state={
            _courses: null,
            _loaded: false       
        }
    }
    componentDidMount=()=>{
        this._getCourseInfo();
    }

    _getCourseInfo(){
        
        const apiUrl = 'https://tickau.engpool.com/cbt/ajax_course_info.cshtml?cno='+this.props.courseno+'&uno='+window.users.userno;

        axios.get(apiUrl)
            .then(data => {
                this.setState({
                    _courses: data,
                    _loaded: true
                });
                console.log("코스자료 자료호출 완료");
                
            })
            .catch(error => {
                console.log(error);
                alert('데이터를 가져오는데 실패했습니다.');
            });
    }

    thisMovie(movieName){
        return document[movieName];
    }	
    

    GoNext=()=>{
        this.props.history.push('ready/7/'+this.props.courseno);
    }
    render(){
        document.querySelector('body').style='background-color: #4688c5';

        if(!this.state._loaded)
            return(<div>loading</div>)

            let title = this.state._courses.data.rows[0].s_name;

            console.log("call render");

    return (
        
        <div className="body" style={{backgroundColor:"#4688c5",overflow:"hidden"}}>       
            <div className="Last_box">
            <img src="../../images/ico_mock.png"/>
            <h1>모의고사</h1>
            <div style={{marginTop:20}}><span>{title}</span></div>
            
            <div className="btn_area">
            
                <button className="btn_start" id="" onClick={this.GoNext}>시작하기</button> 
            </div>
            </div>        

        </div>
    )
    }
}
export default SeatInfo;