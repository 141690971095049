import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import '../assets/sub/sstyle.css';

class Direction extends Component{
    constructor(props){
        super(props);

        this.state={            
            _sno    :   props.sno,
            _qno    :   props.qno,
            _QAudio :   this.props.questions.QueSound,
            _QImage :   this.props.questions.QueImage,        
            _QText  :   this.props.questions.QueQuestion, 
            _QType  :   this.props.questions.QueType, 
            _CatNo  :   this.props.questions.CatNo, 
            _QueTips:   this.props.questions.QueTips, 
            _QueTime:   this.props.questions.QueTime,     
            _QUrl   :   this.props.qurl,
            _Question:  props.questions,
            _WaitTime: this.props.questions.WaitTime,
             _objAudio: [],
            _minutes:0,
            _seconds:0,
            _myInterval: null,
            _NextOk: 0         
        };
    }

    componentDidMount(){
        
        this.state._seconds = this.state._WaitTime;
        

        if(this.props){
            this.PlayAudio(this.state._QAudio);
            this.startTimer();
        }

        //ui.convertHtml("");

        var sPart       = this.state._QAudio.substring(4,5).toString();
        var sTask       = this.state._QAudio.substring(6,7).toString();

        document.querySelector('#id_Text').innerHTML = this.state._QText;
        document.querySelector('#id_Title').innerHTML = "Part " + sPart + "-" + "Task " + sTask;       
        
    }
    startTimer(){
        
        this.state._myInterval = setInterval(() => {
            const { _seconds, _minutes } = this.state

            if (_seconds > 0) {
                this.setState(({ seconds }) => ({
                    _seconds: _seconds - 1
                }))
            }
            if (_seconds == 0) {
                if (_minutes == 0) {
                    clearInterval(this.state._myInterval)
                    this.setState = {
                        _NextOk: 1
                    }

                    

                } else {
                    this.setState(({ _minutes }) => ({
                        _minutes: _minutes - 1,
                        _seconds: 59
                    }))
                }

                
            } 

            console.log('minutes='+this.state._minutes);
            console.log('seconds='+this.state._seconds);
        }, 1000)
    }    

  
    StopAudio(){
        if(this.audio){            
            console.log("Audio stop!")
            this.audio.pause();
            this.audio.currentTime = 0;
            this.audio.remove();
            if(this.state._myInterval)
                clearInterval(this.state._myInterval)
        }
    } 

    PlayAudio=(_sndfile)=>{
            console.log("오디오 시작");
            var self = this;
            this.audio = document.createElement('audio');
            this.juicePos = 0;
            this.audio.src = this.state._QUrl + "/audio/" + _sndfile;
            this.audio.style.display = "none"; //added to fix ios issue
            this.audio.autoplay = true; //avoid the user has not interacted with your page issue
            this.audio.onended = function () {
                self.audio.remove(); //remove after playing to clean the Dom
                
            };
            document.body.appendChild(this.audio);

            this.audio.addEventListener("timeupdate", function () {
                self.juicePos = self.audio.currentTime / self.audio.duration;
                console.log(self.juicePos);
            });

        }
        GoQuestion(){            
                   
        }

        GoNext=()=>{

            let self = this;

            self.StopAudio();
            if(self.state._myInterval)
                clearInterval(self.state._myInterval)

            let path = "/test/" + self.state._sno + "/"+(parseInt(self.props.qno) + 1)
          //  let hstory = useHistory();
          self.props.history.push(path);
            
        }
        
        render(){
            let content = this.state._QText;        
            var _id_InfoStyle = this.state._QText === "undefined" ? { visibility : "hidden"} : {}

            if(this.state._NextOk===1){
                return(
                    <Redirect to={"/test/"+ this.state._sno + "/"+ (parseInt(this.props.qno) + 1) }/>
                    )     
            }

        return(
            
            <div className="body">
            <div className="navi2">	</div>        
            <div className="contents_guide">                                
                {/*
                <div className="volume">
            <div className="volume_btn"> <img src="../images/question/btn_volume_normal.png" onClick={this.OnVolumeSliderShow}/> </div>
                <div id="id_Slider_Volume" style={{left:'-49px', top:'47px', width: '51px', height:'134px', position:'absolute', display: 'none', zindex:'999', background : '../QuestionHtml/Images/volume_control_bg.gif'}} onClick="event.cancelBubble = true;">
                    <iframe id="id_VolumeCtrl" style={{margintop:'9px',width:'100%',height:'124px'}} src="../QuestionHtml/VolumeCtrl.htm" frameBorder="0"></iframe>
                </div>
                </div>   
                */}             
                <div className="direction_box">
                            <div className="title" id="id_Title">&nbsp;{this.state._QAudio}</div>
                    <div className="dir_txt" id="id_Text">&nbsp;{content}</div>
                    <div className="dir_info" id="id_Info" style={_id_InfoStyle}>&nbsp;
                    <button onClick={this.GoNext} style={{width:120}}>Next</button>                    
                    </div>
                    
                </div>
            <div className="second_only">Next page in <span class="color_re" id="id_Second">&nbsp;{this.state._seconds}</span> Seconds</div>
            
                                
            </div>
        </div>
        )

       
    }
    componentWillUnmount(){
        this.StopAudio();
        clearInterval(this.state._myInterval)
    }
    
}

export default Direction;