import React from 'react';
import { Link } from 'react-router-dom';


const idStep2 = (props) => {
    const webtoon = props.webtoon;
    return (
        <div className="body">
        <div className="navi"> </div>
        <div className="contents_guide1"> 
        
        <div className="volume">
            <div className="volume_btn"> <img src="./images/btn_volume_normal.png" onclick="OnVolumeSliderShow();" onload="InitButtion(this, true);"/> </div>
            <div id="id_Slider_Volume" style={{left:'-49px', top:'47px', width: '51px', height:'134px', position:'absolute', display: 'none', zindex:'999', background : '../QuestionHtml/Images/volume_control_bg.gif'}} onclick="event.cancelBubble = true;">
                <iframe id="id_VolumeCtrl" style={{margintop:'9px',width:'100%',height:'124px'}} src="../QuestionHtml/VolumeCtrl.htm" frameborder="0"></iframe> 
            </div>
        </div>
        
        <div className="text_box">
            <div className="title">Identification – Step 2</div>
            <div className="step">
                <ol>
                    <li>
                        <p className="en24 bold color_sky">Step 01. </p>
                        <p><span className="en24 bold">Put your finger on the device.</span><br/>
                            <span className="kr20 bold">(등록된 지문을 올려놓으세요.)</span> </p>
                    </li>
                    <li>
                        <p className="en24 bold color_sky">Step 02.</p>
                        <p><span className="en24 bold">Click the Button.</span><br/>
                            <span className="kr20 bold"> (아래 버튼을 클릭하세요.) </span></p>
                        <p className="btn"><Link to="/ready/4"><img src="./images/btn_check_normal.png" onload="InitButtion(this, true);"/></Link></p>
                    </li>
                </ol>
            </div>
        </div>
        </div>
        </div>
    )
}

export default idStep2;