import React from 'react';

const Header = () => (
        <div className="header_mock"> 
            <span>KAU Flight Training Center</span>
            <div className="user">
            <span id="id_UserBirth" class="birth">&nbsp;</span><span className="bar" id="id_Bar">&nbsp;</span><span id="id_UserName" className="name">&nbsp;</span>
            </div>
        </div>        
)
export default Header;