import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import Qtype1 from "../component/Qtype1";
import Qtype2 from "../component/Qtype2";
import '../assets/question/Volume.js';
import Common from '../assets/Common.js';
import ReactSWFCompat from 'react-swf/compat';
import RecordRTCTest from '../component/reactRTC';
import QuestionHome from '../container/QuestionHome';
import { reduceEachTrailingCommentRange, isConstructorDeclaration } from 'typescript';
import AudioAnalyser from './AudioAnalyser';
import ReactMicCom from '../component/ReactMic'

const SWF_ID_PREFIX = '__MyExternalInterfaceExample_SWFID_';
const SWF_CALL_NAME_PREFIX = '__MyExternalInterfaceExample_SWFCall_';

let nextUID = 0;

class Questions extends Component{
			
    constructor(props){
        super(props);

        this.state={
            _qno    :   props.qno,
            _sno    :   props.sno,   
            _uno    :   props.uno,     
            _QAudio :   props.questions.QueSound,
            _QImage :   props.questions.QueImage,        
            _QText  :   props.questions.QueQuestion, 
            _QType  :   props.questions.QueType, 
            _CatNo  :   props.questions.CatNo, 
            _QueTips:   props.questions.QueTips,                         
            _Qcode:     props.questions.QCode, 
            _QUrl   :   props.qurl,            
            _PartInfo:  props.partinfo,
            _Listen_timer: null,
            _Response_timer: null,
            _SayAgain_end_timer : null,
            _minutes:0,
            _seconds:30,
            stylePath:'../assets/question/qstyle.css'            ,
            _RecordTime: props.questions.RecTime,
            _ListenTime: 15,
            TInterval : null,
            _nTime:0,
            _StudyTime:props.questions.StudyTime,
            _WaitTime:props.questions.WaitTime,
            _QueTime:props.questions.QueTime,
            myRInterval:null,
            _PartTile       : ["Readback/Hearback","Checking / Clarifying / Confirming / Informing","Role-play & Follow-up"," Role-play, Abnormal/Emergency Related","Reporting"],
            _SayAgainInfo: 0,
            _userRecordingFile: "",
            _tryno:0,
            zoom:0,
            _dispAudioVisual:false,
            _mic:null,
            _nowStudyTime:0,            

        }
        
        this.startTimer = this.startTimer.bind(this)
        this.startRTimer = this.startRTimer.bind(this)
        this._uid = nextUID++;

        window[SWF_CALL_NAME_PREFIX + this._uid] = this.loadedComplete.bind(this);
        this.GoNext = this.GoNext.bind(this)
        this.OnGoNextReady = this.OnGoNextReady.bind(this)
        this.lastquiz = props.lastquiz; //20210117 mdo
        this._timeSeconds = 3000;
        
    }

    
    invokeSWFMyCallback(arg) {
        // Beware; SWF Player does not sufficiently escape serialized arguments.
        return this._swfPlayerNode.myCallback(arg);
      }

    SayAgain=()=>{
        
        //this.PlayAudio(this.state._QAudio);
        //let id_SayAgain = document.querySelector('.control_panel #id_SayAgain')
        this.OnSayAgain();

    }

    disableSayAgain=()=>{
        this.state._SayAgainInfo = 1;
        let id_SayAgain = document.querySelector('.control_panel #id_SayAgain')
        //let id_SayAgain = document.querySelector('#id_SayAgain')
        
        const styleSayAgain={
            cursor  :   "default",
            filter : "alpha(opacity=50)"
        }
        
        id_SayAgain.src= "./Images/btn_sayagain_disable.png";
        id_SayAgain.onClick = null;

        //Common.__EnableButtion__(id_SayAgain, true);
        
    }

    EnableSayAgain=()=>{
        let id_SayAgain = document.querySelector('.control_panel #id_SayAgain')
        //let id_SayAgain = document.querySelector('#id_SayAgain')
            
            id_SayAgain.style.cursor = "hand";
            id_SayAgain.style.filter = "alpha(opacity=100)";
            id_SayAgain.src= "./Images/btn_sayagain_normal.png";
            id_SayAgain.onClick=()=>{this.OnSayAgain() }
        
        //Common.__EnableButtion__(id_SayAgain, true);
        
    }

    OnSayAgain=()=>{
        
        
        /*
        this.state._SayAgainInfo = 1
        
        var id_SayAgain = document.querySelector('.control_panel #id_SayAgain')
        id_SayAgain.onClick = null;
        this.state._SayAgainInfo = 1;
        const styleSayAgain={
            cursor  :   "default",
            filter : "alpha(opacity=50)"
        }
        
        id_SayAgain.src= "./Images/btn_sayagain_disable.png";
        */
       if(this.state._SayAgainInfo===1)
        return;
        
       if(this.state._SayAgainInfo===0){
        this.state._SayAgainInfo = 1
        this.disableSayAgain();
            if(this.state.myRInterval)
                clearInterval(this.state.myRInterval);     
            this.StopAudio();
            this.DisablePreparationTime();
            this.SetListening();
        }        

        /*
        
        Common.__EnableButtion__(id_SayAgain, false);
        setTimeout(function() {
             document.querySelector('id_QTemplete').OnSayAgain();
             }, 10);

        setTimeout(function(){ 
            document.querySelector('id_QTemplete').DisableSayaAain(); 
            if(typeof document.querySelector('id_QTemplete').HeadsetEnable == 'function') document.querySelector('id_QTemplete').HeadsetEnable();
        }, 100);

        this.RecordStop();
        
        document.querySelector('id_GoNext').src = "./images/btn_next_normal.png";
        document.querySelector('id_GoNext').init_load = false;
        //InitButtion(id_GoNext, false);
        document.querySelector('id_GoNext').style.cursor = "default";
        document.querySelector('id_GoNext').onclick = null;
        
        //clearTimeout(this._Next_timer);
        
        //this.id_ProgressRec.parentNode.parentNode.className = "response line disabled";
        
        clearTimeout(this._Listen_timer);
        clearTimeout(this._Response_timer);
        clearTimeout(this._Listen_end_timer);
        clearTimeout(this._SayAgain_end_timer);
        
        //setTimeout(function() { id_MediaControl.SetEQMeterBandsColor(__RGB__(255, 255, 100), __RGB__(255, 255, 100), __RGB__(255, 255, 100)); }, 500);
        document.querySelector('id_RecLow').style.visibility = "hidden";
        document.querySelector('id_msg_warning').style.visibility = "hidden";
	    */
    }

    startRTimer=(_type)=>{

        let self = this;

        var _rate = 0
        this.state._nTime = 0        

        if(self.state.myRInterval)
            clearInterval(self.state.myRInterval);   

        console.log('pre interval=' + this.state.myRInterval);
        this.state.myRInterval = setInterval(() => {
            console.log('interval=' + this.state.myRInterval);
            

            if(_type =='S'){
                _rate = (this.state._nTime) / this.state._StudyTime
                console.log('study seconds='+this.state._nTime);
                document.querySelector('#id_StudyTime').innerHTML= parseInt(this.state._StudyTime) - parseInt(this.state._nTime)
                
            }
            else if(_type=='P'){
                _rate = (this.state._nTime) / this.state._WaitTime
                console.log('preparation seconds='+this.state._nTime);
                document.querySelector('#id_PlayTime').innerHTML= parseInt(this.state._WaitTime) - parseInt(this.state._nTime)
                
            }
            else{
                _rate = (this.state._nTime) / this.state._RecordTime
                console.log('record seconds='+this.state._nTime);
                document.querySelector('#id_RecTime').innerHTML= parseInt(this.state._RecordTime) - parseInt(this.state._nTime)
                
            }

            this.state._nTime ++;

            //console.log('record minutes='+this.state._minutes);
            

            
            if(_type=='S'){
                if(this.state._nTime > this.state._StudyTime ){
                    console.log('clear study seconds');
                    clearInterval(self.state.myRInterval);                                
                    this.DisableStudying();
                    this.OnInitLoad();  
                    this.state._SayAgainInfo = 0;          
                    this.SetListening();
                    
                    if(document.querySelector('#id_Question')){
                        if(this.state._QText =="")
                        document.querySelector('#id_Question').style = "visibility:hidden";
                        else{
                            document.querySelector('#id_Question').style = "visibility:";
                            document.querySelector('#id_Question').innerHTML  = this.state._QText;
                        }
                    }
                    
                }         
            }  
            else if(_type=='P'){
                if(this.state._nTime > this.state._WaitTime ){
                    console.log('clear preparation seconds');
                    clearInterval(this.state.myRInterval);                                
                    this.DisablePreparationTime();                                    
                    /*
                    this.OnInitLoad();            
                    this.SetListening();                
                    */
                    this.sendToRecordStart();  
                    this.PlayThingSound(); 
                    this.SetResponse(this.state._RecordTime);                
                }   
            }              
            else {

                if(this.state._nTime > this.state._RecordTime ){
                    console.log('clear record seconds');
                    clearInterval(this.state.myRInterval);            
                    
                    this.sendToRecordStop()

                    //setTimeout( this.GoNext(), 2000);                   
                    
                }           
            }

            if(_type=='S')
                this.ProgressStudy(_rate);
            else if(_type=='P')
                this.ProgressListen(_rate)
            else
                this.ProgressRec(_rate)

                console.log('ntime=',this.state._nTime);

        }, 1000)
    }

    startTimer(_type){
        var self = this;
        //var _timeSeconds = 3000;

        this.state.TInterval = setInterval(() => {
                    
            if (this._timeSeconds > 0) {                   //mrdo 20210117                          
                this._timeSeconds  = this._timeSeconds - 1;
                this.state._nowStudyTime = this.state._nowStudyTime+1;                
               
            }
            if (this._timeSeconds==0) {                
                    clearInterval(this.state.TInterval);
                    this.state._nowStudyTime = 0;
            } 

            
            console.log('home seconds='+this._timeSeconds);

            this.SetExamRemainTime(this._timeSeconds,this._timeSeconds);
        }, 1000)
    }

    SetExamRemainTime(sTime, nSecond)
    {
        var mObj = document.getElementById("id_RemainTime");
        if(mObj)
        {
            mObj.innerHTML = parseInt(nSecond/60) + " : " + nSecond%60;   
            if(nSecond < 600) 
            {
                //mObj.style.color = "red";
                /*
                setTimeout(function() 
                { 
                    var mObj2 = document.getElementById("id_RemainTime");
                    if(mObj2) mObj2.style.color = "#15455e"; 
                }, 500);
                */
            }
        }
    }    
    OnInitLoad(){              

        //this.SetExamRemainTime(this.GetRemainTime(), this.GetRemainTimeSec());
        //if(this.GetQImage() == "default.jpg") document.querySelector('id_QTemplete').location.replace("Type2.htm");
        //else document.querySelector('id_QTemplete').location.replace("Type1.htm");
        
        var sPart       = this.props.questions.QueTaskName.substring(1,2).toString();
        var sTask       = this.props.questions.QueTaskName.substring(3,4).toString();
        var sTaskSubName = "";
        var nQNo        = parseInt(this.state._qno)-1;        
        if(document.querySelector('#id_Part'))
            document.querySelector('#id_Part').innerHTML = sPart;        
        
        if(document.querySelector('#id_Question'))
            document.querySelector('#id_Question').innerHTML = '&nbsp';

        if(sPart=="1"&& sTask=="A")
            sTaskSubName =  "&nbsp;" + this.state._PartTile[0]
        else if(sPart=="1"&& sTask=="B")
             sTaskSubName =  "&nbsp;" + this.state._PartTile[1]
        else if(sPart=="2"&& sTask=="A")
            sTaskSubName = "&nbsp;" + this.state._PartTile[2]            
        else if(sPart=="2"&& sTask=="B")
            sTaskSubName = "&nbsp;" + this.state._PartTile[3]                        
        else if(sPart=="2"&& sTask=="C")
            sTaskSubName = "&nbsp;" + this.state._PartTile[4]                          
        
            document.querySelector('#id_Task').innerHTML = sTask + ".";
            document.querySelector('#id_PT_Name').innerHTML = sTaskSubName;

       // document.querySelector('#id_PT_Name').innerHTML = this.GetPartTaskName(sPart, sTask);
                
        var bFind = false;
        var taskNum = nQNo
        var self = this;
        bFind = this.DrawQuestionNo("1", "A", sPart, sTask, nQNo, bFind,this.state._PartInfo.p1ta);                
        nQNo = nQNo - (parseInt(this.state._PartInfo.p1ta)+1);        

        bFind = this.DrawQuestionNo("1", "B", sPart, sTask, nQNo, bFind,this.state._PartInfo.p1tb);
        
        nQNo = nQNo - (parseInt(this.state._PartInfo.p1tb)+1);

        bFind = this.DrawQuestionNo("2", "A", sPart, sTask, nQNo, bFind,this.state._PartInfo.p2ta);
        nQNo = nQNo - (parseInt(this.state._PartInfo.p2ta)+1);            
        bFind = this.DrawQuestionNo("2", "B", sPart, sTask, nQNo, bFind,this.state._PartInfo.p2tb);        
         nQNo = nQNo - (parseInt(this.state._PartInfo.p2tb)+1);
        bFind = this.DrawQuestionNo("2", "C", sPart, sTask, nQNo, bFind,this.state._PartInfo.p2tc);   
             
        
        //this.rndSoundFile();
        
    }

   

    DrawQuestionNo(sPart1, sTask1, sPart2, sTask2, nQNo2, bFind,_nQMax)
    {

        var sHtml = "";
        for(var i = 1; i <= _nQMax; i++)
        {
            sHtml += "<li>" + i + "</li>";
        }
        document.getElementById("id_" + sPart1 + "_" + sTask1).innerHTML = sHtml;

        sHtml = "";
        for(var i = 1; i <= _nQMax; i++)
        {
            sHtml += "<li" + (bFind ? "" : " class='finish'") + ">" + i + "</li>";
            if(bFind == false && sPart1 == sPart2 && sTask1 == sTask2 && i == nQNo2) bFind = true;
        }
        document.getElementById("id_" + sPart1 + "_" + sTask1).innerHTML = sHtml;
        //id_1_A

        return bFind;
    }

    /*
    EnableSayAgain(){    

        const id_SayAgain = {
            cursor : 'hand',
            filter : "alpha(opacity=100)"
        }        
        Common.__EnableButtion__(document.querySelector('id_SayAgain'), true);
        document.querySelector('_SayAgain').onclick = function() { this.OnSayAgain(); }
    }
    */


    GoNext=()=>{
        var self = this;
        
        this.StopAudio();    
        if(self.state.myRInterval)
            clearInterval(self.state.myRInterval);                                

        let path = "/test/"+ self.state._sno + "/"+ (parseInt(self.props.qno) + 1);        
      //  let hstory = useHistory();
      this.state._SayAgainInfo = 0
    
      this._setQuestions();

      self.props.history.push(path); 
        
        
    }

    
   
    SetResponse(nTime)
    {
            

        let self = this;

        
            document.querySelector('#id_RecTime').innerHTML = parseInt((nTime*1000) / 1000);
        
            /*
            var nRet = id_MediaControl.MicRecordStart(parent.window.external.GetCurrPath() + "ResponseData/" + sFile);
            if(nRet == -1) window.external.Alert("녹음 시작 오류\r\n마이크 장치가 연결되어있지 않거나 오류가 발생하였습니다.");
            */
           if(document.querySelector('.progress_box2 #id_ProgressRec')){
                document.querySelector('.progress_box2 #id_ProgressRec').parentNode.parentNode.parentNode.parentNode.className = "mic_box";    
            } 
            if(document.querySelector('.progress_box #id_ProgressRec')){
               document.querySelector('.progress_box #id_ProgressRec').parentNode.parentNode.className = "response line response_view";        
            }
    
            document.querySelector('#id_RecLow').style.visibility = "hidden";        
            document.querySelector('#id_msg_warning').style.visibility = "hidden";
            
            /*
            while(_AudioLevelList.length > 0) { _AudioLevelList.pop(); }
            _Response_timer = setTimeout( function() { ProgressRec(nTime, GetTickCount()); }, 100);
            */
    
           var id_GoNext = document.querySelector('#id_GoNext');
           var id_QTemplete = document.querySelector('#id_QTemplete');
    
           //Common.__EnableButtion__(id_GoNext, true);
           id_GoNext.style.cursor = "hand";
           if(document.querySelector('#id_Response'))
               document.querySelector('#id_Response').className= "mic_box"
    
    
           self.startRTimer('R');
    
           id_GoNext.onclick=()=>{ this.OnGoNextReady(id_GoNext); };
            
           //if(typeof id_QTemplete.ResponseEnable == 'function') id_QTemplete.ResponseEnable();
            //setTimeout(function() { id_MediaControl.SetEQMeterBandsColor(_que_RGB__(255, 130, 130), __RGB__(255, 130, 130), __RGB__(255, 130, 130)); }, 50);

            
        

       
    }
    ProgressRec(nRate){        

       var nPgs = -119 + 119 * nRate;
        var nPgs1 = -200 + 200 * nRate;
        if(nPgs > 0) nPgs = 0;
        if(nPgs1 > 0) nPgs1 = 0;
        if(document.querySelector('.progress_box2 #id_ProgressRec'))
            document.querySelector('.progress_box2 #id_ProgressRec').style.backgroundPosition = nPgs1 + "px";

        if(document.querySelector('.progress_box #id_ProgressRec'))
            document.querySelector('.progress_box #id_ProgressRec').style.backgroundPosition = nPgs + "px";
        
        /*    if(document.querySelector('.progress_box .progress2'))
            document.querySelector('.progress_box .progress2').style.backgroundPosition = nPgs1 + "px";
            */
            
            /*
        var nCurr = GetTickCount() - nTickCount;
        document.querySelector('#id_RecTime').innerHTML = parseInt((nTime + 999 - nCurr) / 1000);
        
        if(nRate > 0) 
        {
            var nPgs = -119 + 119 * nCurr / nTime;
            if(nPgs > 0) nPgs = 0;
            id_ProgressRec.style.backgroundPosition = nPgs + "px";
            if(typeof id_QTemplete.ResponseProgress == 'function') id_QTemplete.ResponseProgress(nCurr, nTime);
        }
        */

        if(nRate > 0) 
        {
            //id_MediaControl.MicRecordStop();
            //setTimeout(function() { if(typeof document.querySelector('id_QTemplete').OnResponseEnd == 'function') document.querySelector('id_QTemplete').OnResponseEnd(); }, 500);
            return;
        }
        
        //_Response_timer = setTimeout( function() { ProgressRec(nTime, nTickCount); }, 100);
        
    }   
    ProgressStudy(nRate){        


        var nPgs = -119 + 119 * nRate;        
        if(nPgs > 0) nPgs = 0;        

        if(document.querySelector('#id_ProgressStudy'))
            document.querySelector('#id_ProgressStudy').style.backgroundPosition = nPgs + "px";

        if(nRate > 0) 
        {
            //id_MediaControl.MicRecordStop();
            //setTimeout(function() { if(typeof document.querySelector('id_QTemplete').OnResponseEnd == 'function') document.querySelector('id_QTemplete').OnResponseEnd(); }, 500);
            return;
        }
        
    }   
    OnGoNextReady=(obj)=>{
        obj.src = "../images/btn_confirm1_normal.png";
        obj.width = 166;
        obj.height = 56;
        
        obj.init_load = false;
        //InitButtion(obj, true);
        var self = this;
        obj.onclick = function() { setTimeout( function() { self.sendToRecordStop();}, 200); } //self.GoNext(); 
        this._Next_timer = setTimeout( function() 
            { 
                obj.onclick = function() { self.OnGoNextReady(obj); };
                obj.style.cursor = "hand";
                obj.src = "../images/btn_next_normal.png";
                obj.init_load = false;
                //InitButtion(obj, true);
            }, 3000);
    }
   
    SetPreparation=()=>{    
        const _WaitTime = this.state._WaitTime;
        //document.querySelector('#id_ProgressStudy').parentNode.parentNode.className = "studying line studying_view";
        //setTimeout( function() { this.ProgressStudy(nTime, this.GetTickCount()); }, 100);

        //   document.querySelector('#id_ProgressStudy').parentNode.parentNode.className = "studying line studying_view disabled";

         

        this.DisableStudying();    
        this.DisabledRecording();
            if(document.querySelector('#id_listen_title'))
                document.querySelector("#id_listen_title").src = "./images/title_2_2.gif";     

        


    var nRet;    

    
    document.querySelector('#id_ProgressStudy').className = "studying line studying_view";
    

    document.querySelector('#id_PlayTime').innerHTML = this.state._WaitTime; //parseInt((id_MediaControl.GetDuration() + 999) / 1000);
    if(document.querySelector('#id_Listen'))
        document.querySelector('#id_Listen').className = "headset_box";

    if(document.querySelector('.progress_box #id_ProgressListen'))
        document.querySelector('.progress_box #id_ProgressListen').parentNode.parentNode.className = "response line response_view"; 
        
    if(document.querySelector('#id_listen_title'))
            document.querySelector("#id_listen_title").src = "./images/title_2_2.gif";
    
        this.ProgressListen(0);

        document.querySelector('#id_ProgressListen').parentNode.parentNode.className = "listening line listening_view";                

        this.startRTimer('P');
    }    
    
    SetStudying(){    

        this.DisableListen();
        const id_StudyTime = this.state._StudyTime;
        document.querySelector('#id_ProgressStudy').parentNode.parentNode.className = "studying line studying_view";        
            document.querySelector('#id_ProgressStudy').className = "progress";
        //setTimeout( function() { this.ProgressStudy(nTime, this.GetTickCount()); }, 100);
        document.querySelector('#id_Question').innerHTML  = "You have 15 seconds to examin the 1st screen provided.";
        this.ProgressStudy(0);
                
        this.startRTimer('S');
    }
    DisableStudying(){    
        
        if(document.querySelector('#id_ProgressStudy').className != "studying line hide1") 
                document.querySelector('#id_ProgressStudy').className = "studying line disabled";
                

        if(document.querySelector('#id_ProgressStudy'))
            document.querySelector('#id_ProgressStudy').parentNode.parentNode.className = "studying line studying_view hide1";
    }
    DisablePreparationTime(){    
        this.DisableListen();
        if(document.querySelector('#id_listen_title'))
            document.querySelector("#id_listen_title").src = "./images/title_2.gif";
    }    
    

    DisableListen=()=>{
        if(document.querySelector('#id_ProgressListen'))
            document.querySelector('#id_ProgressListen').parentNode.parentNode.className = "listening line disabled";

        if(document.querySelector('#id_Listen'))
            document.querySelector('#id_Listen').className = "headset_box disabled";
        

        this.state._SayAgainInfo = 1;
        /*        
        if(document.querySelector('#id_SayAgain').onmousedown != null) 
        {
           
            //if(typeof document.querySelector('id_QTemplete').HeadsetDisable == 'function') document('id_QTemplete').HeadsetDisable();
            
            const id_SayAgain = {
                cursor:"hand",
                filter:"alpha(opacity=100)"
            }            
            
            //__EnableButtion__(id_SayAgain, true);
            this._SayAgain_end_timer = setTimeout(function() {

                    const id_SayAgain = {
                        cursor : "default",
                        filter : "alpha(opacity=50)",
                    }                    
                    
                    //__EnableButtion__(id_SayAgain, false);
                    document.querySelector('id_SayAgain').onClick = null;


                    //if(typeof document.querySelector('id_QTemplete').ListenDisable == 'function') document.querySelector('id_QTemplete').ListenDisable();
                }, 3000);
        }
        */

        
        //else if(typeof document.querySelector('id_QTemplete').ListenDisable == 'function') document.querySelector('id_QTemplete').ListenDisable();

        /*
        const id_SayAgain = {
            cursor : "default",
            filter : "alpha(opacity=50)",
        }                    
        
        //__EnableButtion__(id_SayAgain, false);
        document.querySelector('id_SayAgain').onClick = null;
        */
       this.disableSayAgain();

    }    

    PlayThingSound=()=>{
        this.PlayAudio("thing.mp3");
    }  
    
        componentDidMount(){

            //this.startTimer();

            if(this.state._qno==2)
                this.startTimer();

            this.OnInitLoad();
   //this.PlayAudio(this.state._QAudio);

      //this.PlayAudio(this.state._QAudio);

            if(document.querySelector('#id_Question')){
                if(this.state._QText ==""){
                    document.querySelector('#id_Question').style = "visibility:hidden";
                    if(this.state._QImage.indexOf("default") === -1)
                        document.querySelector('#id_Question').parentNode.style = "visibility:hidden";
                }
                else{
                    document.querySelector('#id_Question').style = "visibility:";
                    document.querySelector('#id_Question').innerHTML  = this.state._QText;
                }
            }
            
                
            if(this.state._StudyTime>0){
                this.DisableStudying();
                this.SetStudying();
                
            }
            /*
            else if(this.state._WaitTime>0){
                this.DisablePreparationTime();
                this.SetPreparation();
            }
            */
            else{
                this.DisableListen();
                //this.DisabledRecording();
                this.state._SayAgainInfo = 0;
                this.SetListening();
            }
                
                //this.thisMovie('kau_record').addEventListener('loadedComplete', this.loadedComplete); 2020916
                //this.EnableSayAgain();

                //this.state._userRecordingFile = this.rndSoundFile();
                

            //시간설정

            //ui.convertHtml("");

            
        }
        componentDidUpdate(){

            //this.sendToRecordStop();

            this.OnInitLoad();            

            if(document.querySelector('#id_Question')){

                if(this.state._QText =="")
                document.querySelector('#id_Question').style = "visibility:hidden";
                else{
                    document.querySelector('#id_Question').style = "visibility:";
                    document.querySelector('#id_Question').innerHTML  = this.state._QText;
                }
                /*
                if(this.state._QText =="")
                    document.querySelector('#id_Question').className="descript_box hide";    
                else{
                    document.querySelector('#id_Question').innerHTML  = this.state._QText;
                    document.querySelector('#id_Question').className="descript_box";
                }
                */
            }            
            this.StopAudio();
            if(this.state._StudyTime>0){
                this.SetStudying();
                this.disableSayAgain();
            }
            /*
            else if(this.state._WaitTime>0)                
                this.SetPreparation();
                */
            else{
                this.DisableListen();
                this.DisabledRecording();
                this.state._SayAgainInfo = 0;
                this.SetListening();
            }

            //this.state._userRecordingFile = this.rndSoundFile();
            
            document.querySelector('#id_SayAgain').onClick = function(){ this.SayAgain() }

            //his._setQuestions();
            
        }

        componentWillUpdate(){
            console.log('componentWillUpdate');
            this.StopAudio();
        }

        
   
        _setQuestions=()=>{
         
            /*
		if(_soundList[soundOrder] != null){ //20170710 mrdo 
			_userAnswer[soundOrder] = tbSoundFile;
        }
       
		var obj ={};
		//obj.setno						= setno;
        //obj.userno						= userno;
        
		
		//obj.mtype						= 'S';
        
        
		$.post(questionUrl, obj, function (oj) {
			
			changedState(_stepOrder);
			alert("학습결과 전송이 완료되었습니다.");			
			
        }, "json");
        */
	
            const apiUrl = 'https://tickau.engpool.com/cbt/ajax_question_save.cshtml';
            var callback = "callback";
            var CourseNo   = this.state._sno;    
            var UserNo = window.users.userno;     // R:Radet, T:Tower
            var QueNo = this.state._qno;
            var QCode = this.state._Qcode;
            //var UserAns = this.state._userRecordingFile;
            var UserAns = this.refs.child.recFileName;
            var AnsCheck = "anscheck";
            var TryCount = this.state._tryno;

            var optionAxios = {
                headers: {
                    //'Content-Type': 'application/x-www-form-urlencoded'
                    //'Content-Type': 'multipart/form-data'            
                    'Content-Type': 'access-control-request-method' 
                    
                }
            }
            let form = new FormData() 
            form.append('cno', CourseNo)
             form.append('uno',UserNo) 
             form.append('qno',QueNo) 
             form.append('qcode',QCode) 
             form.append('userans',UserAns) 
             form.append('anscheck',AnsCheck) 
             form.append('tryno',TryCount) 

             form.append('time',this.state._nowStudyTime)  //mrdo 20210117
             form.append('flag',this.props.flag)  //mrdo 20210117
             
             axios.post(apiUrl, form) 
             .then( response => { 
                 console.log('response : ', JSON.stringify(response, null, 2)) 
                 console.log('저장완료')
                }
            )
             .catch( error => { 
                alert('문항 저장에 실패했습니다.');
                return;
                console.log('failed', error)
             })

            /*

            axios.post(apiUrl, {
                headers: { 'Content-type': 'application/x-www-form-urlencoded' },
                cno: CourseNo,
                uno: UserNo,
                qno: QueNo,
                userans: UserAns,
                anscheck: AnsCheck,
                trycount:TryCount
            })
            .then( response => { 
                console.log(response) 
            } 
                )
            .catch( response => { 
                alert('문항 저장에 실패했습니다.');
                return;
                console.log(response) 
            } );
            */
    
           
        }
    
        componentWillReceiveProps(nextProps){
            console.log('componentWillReceiveProps');
            if(String(this.props.qno) != "undefined" && this.state._qno != nextProps.qno){
                this.setState({
                    _qno    : nextProps.qno,
                    _QAudio :   nextProps.questions.QueSound,
                    _QImage :   nextProps.questions.QueImage,        
                    _QText  :   nextProps.questions.QueQuestion, 
                    _QType  :   nextProps.questions.QueType, 
                    _CatNo  :   nextProps.questions.CatNo, 
                    _QueTips:   nextProps.questions.QueTips, 
                    _QUrl   :   nextProps.qurl,            
                    _PartInfo:  nextProps.partinfo,
                    _RecordTime: nextProps.questions.RecTime,
                    _StudyTime: nextProps.questions.StudyTime,
                    _WaitTime: nextProps.questions.WaitTime,
                    _ListenTime: 15,
                    _Qcode:     nextProps.questions.QCode
                })

                

                //this.sendToRecordStop();
                this.ProgressRec(0);
                this.SetListening();
                //this._setQuestions();
                
            }
        }

      

        togglePlayPause(){

            if(this.state.audio.paused){
                // btn.className = 'pause';
                this.state.audio.play();			
            }
            else{              //btn.className = "play";
                this.state.aduio.pause();
            }
        }

        StopAudio() {
            if (this.audio) {
                this.audio.pause();
                this.audio.currentTime = 0;
                this.audio.remove();
            }
        }

        PlayAudio=(_sndfile)=> {
            this.StopAudio();
            var self = this;            

            if(_sndfile == "thing.mp3")
                console.log("thing start");

            this.audio = document.createElement('audio');            
            this.juicePos = 0;
            this.audio.crossorigin= "anonymous";
            this.audio.src = this.state._QUrl  + "/audio" + "/" + _sndfile;
            this.audio.style.display = "none"; //added to fix ios issue
            this.audio.autoplay = true; //avoid the user has not interacted with your page issue
            document.body.appendChild(this.audio);
            this.audio.onended = function () {
                self.audio.remove(); //remove after playing to clean the Dom

                if(_sndfile == "thing.mp3")
                console.log("thing stop");
                
               console.log("audio remove")
            };
             
            
          
            
            this.audio.addEventListener("timeupdate", function () {
               
                if(_sndfile == "thing.mp3")
                    return;

                

                self.juicePos = self.audio.currentTime / self.audio.duration;

                if(document.querySelector('#id_PlayTime'))
                    document.querySelector('#id_PlayTime').innerHTML = parseInt(self.audio.duration)-parseInt(self.audio.currentTime);
                                

                
                    self.ProgressListen(self.juicePos);

                console.log(self.juicePos);

                if(self.juicePos==1){



                    self.DisableListen();

                    
                    
                        if(_sndfile != "thing.mp3" && self.state._WaitTime>0){
                            self.DisablePreparationTime();
                            self.SetPreparation();                            
                        }
                        else{                     

                            setTimeout(function(){
                            
                                self.DisablePreparationTime();
                                self.PlayThingSound(); 
                                self.SetResponse(self.state._RecordTime);
                                if(self.props.questions.AnsType != "N")                        
                                    self.sendToRecordStart();                        
                                
                            }, 1500);
                        }
                    
                }
            });

        }


        ProgressListen(nRate)
        {
           
            //var nCurr = GetTickCount() - nTickCount;
            //document.querySelector('#id_PlayTime').innerHTML = parseInt((nTime + 999 - nCurr) / 1000);
            if(document.querySelector("#id_ProgressListen")){
            
                /*
                document.querySelector("#id_ProgressListen").style.backgroundImage = "url(./images/progress_add.gif)";               
                document.querySelector("#id_listen_title").src = "./images/title_2_2.gif";
                */
              /* if(this.state._WaitTime >0){

                
                if(document.querySelector('#id_listen_title'))
                    document.querySelector("#id_listen_title").src = "./images/title_2_2.gif";         
                } 
                */    
                
                    var nPgs = -119 + 119 * nRate;
                    if(nPgs > 0) nPgs = 0;
                    document.querySelector('#id_ProgressListen').style.backgroundPosition = nPgs + "px";
            
                
                if(nRate > 0) 
                {
                    /*
                    this.state._Listen_end_timer = setTimeout(function() {                     
                        if(typeof document.querySelector('id_QTemplete').OnListenEnd == 'function') 
                            console.log('33');
                        //id_QTemplete.OnListenEnd(id_MediaControl.PlayStep); 
                        }, 500);
                    return;
                    */
                
                }  
        }
            
            //this.state._Listen_timer = setTimeout( function() { this.ProgressListen(nTime, nTickCount, bAddTime); }, 100);
            
        }    
        
        DisabledRecording=()=>{
            if(document.querySelector('.progress_box2 #id_ProgressRec')){
                document.querySelector('.progress_box2 #id_ProgressRec').parentNode.parentNode.parentNode.parentNode.className = "mic_box disabled";    
            } 
            if(document.querySelector('.progress_box #id_ProgressRec')){
               document.querySelector('.progress_box #id_ProgressRec').parentNode.parentNode.className = "response line response_view disabled";        
            }


            /*            
            if(document.querySelector('#id_ProgressRec'))
                document.querySelector('#id_ProgressRec').parentNode.parentNode.className = "response line response_view disabled";
            */
            let id_GoNext = document.querySelector('#id_GoNext');            
           
        }
        
        SetListening(sFile, sPlayStep)
        {

            this.PlayAudio(this.state._QAudio);
            

            this.DisableStudying();
            
            this.DisabledRecording();

            if(this.state._SayAgainInfo === 0)
                this.EnableSayAgain();
            else    
                this.disableSayAgain();

            var nRet;
            
            
            document.querySelector('#id_ProgressStudy').className = "studying line studying_view";
            //document.querySelector('#id_PlayTime').innerHTML = this.state._QueTime; //parseInt((id_MediaControl.GetDuration() + 999) / 1000);
            
            if(document.querySelector('#id_Listen'))
                document.querySelector('#id_Listen').className = "headset_box";

            if(document.querySelector('.progress_box #id_ProgressListen'))
                document.querySelector('.progress_box #id_ProgressListen').parentNode.parentNode.className = "response line response_view";        
                
                
            document.querySelector('#id_ProgressListen').parentNode.parentNode.className = "listening line listening_view";
                

            /*
            var bAddTime = window.external.GetAddTime();
            if(bAddTime)
            {
                id_ProgressListen.style.backgroundImage = "url(./images/progress.gif)";
                id_listen_title.src = "./images/title_2.gif";
            }        
            _Listen_timer = setTimeout( function() { ProgressListen(id_MediaControl.GetDuration() + 4000 + (bAddTime ? 30000 : 0), GetTickCount(), bAddTime); }, 100);
            */
           this.ProgressListen(0);
        }

        componentWillMount(){
            console.log("will mount");
        }

        componentWillUnmount(){
            this.StopAudio();
        }

        thisMovie(movieName){
            return document[movieName];
        }	

        showVisualize(_this,mic){
            _this.state._dispAudioVisual= mic;
            //_this.setState({dispAudioVisual: mic});
        }

        sendToRecordStart=()=>{
            //record stop()            
            this.refs.child.startRecording();
            //this.setState({_dispAudioVisual:true})

            /* 20200916
            this.thisMovie('kau_record').receive3(this.state._userRecordingFile);
            console.log("record start")
            */
        }

        sendToRecordStart1=()=>{
            //record stop()            
            this.thisMovie('kau_record').receive3(this.state._userRecordingFile);
            console.log("record start")
        }        
        sendToRecordStop1=()=>{
            //record stop()

            //this.DisabledRecording();

            if(this.thisMovie('kau_record')){
              
               console.log("record stop")
               this.ProgressRec(0);               
               this.thisMovie('kau_record').receive2();
            }            
        }    
        sendToRecordStop=()=>{
            //record stop()

            //this.DisabledRecording();            
            this.refs.child.stopRecording();
            /*
            if(this.thisMovie('kau_record')){
              
               console.log("record stop")
               this.ProgressRec(0);               
               this.thisMovie('kau_record').receive2();
            } 
            */           
        }        
            
        loadedComplete=()=>{

            console.log('flash compeleted')
            
        }        

        rndSoundFile=()=> {
            var _soundFile= null;
            var _curDate= new Date();
            var _mDate = "";
            var _mMonth = "";
            var _mHour = "";
            var _mSecond = "";
            var _mMinute = "";
            var _ymd = "";
            
            if(_curDate.getMonth() < 10)
                _mMonth = "0" + (_curDate.getMonth()+1)
            else	
                _mMonth = "" + (_curDate.getMonth()+1)
            
            if(_curDate.getDate() < 10)
                _mDate = "0" + _curDate.getDate()
            else
                _mDate = "" + _curDate.getDate()
            
            if(_curDate.getHours() < 10)
                _mHour = "0" + _curDate.getHours()
            else
                _mHour = ""	+ _curDate.getHours()
            
            if(_curDate.getMinutes() < 10)
                _mMinute = "0" + _curDate.getMinutes();
            else
                _mMinute = ""	+ _curDate.getMinutes();
            
            
            if(_curDate.getSeconds() < 10)
                _mSecond = "0" + _curDate.getSeconds()
            else
                _mSecond = ""	+ _curDate.getSeconds()
            
            
            _ymd = _curDate.getUTCFullYear() + _mMonth;		
            
            //_soundFile = "/kau/"+ _ymd + "/" + _curDate.getUTCFullYear() + _mMonth + _mDate + _mHour + _mMinute + _mSecond+"_"+this.state._uno+"_"+this.state._Qcode;
            _soundFile =  _curDate.getUTCFullYear() + _mMonth + _mDate + _mHour + _mMinute + _mSecond+"_"+this.state._uno+"_"+this.state._Qcode;
  
            console.log(_soundFile);

            this.state._userRecordingFile = _soundFile;
            
            return _soundFile
            
        }
        
        render(){
            const volSlideCtrlStyle={
                left:'135px', 
                top:'52px', 
                width: '51px', 
                height:'134px',
                position:'absolute',
                display: 'none',
                zindex:'999',
                background : '../mages/volume_control_bg.gif'
            }
            const volCtrlStyle={
                margintop:'9px',
                width:'100%',
                height:'124px'
            } 

            var _NextNo =  (parseInt(this.props.qno) + 1)

            //var dispAudioVisual = this.state.dispAudioVisual  ? <AudioAnalyser audio={this.state.dispAudioVisual} /> : '' 

        return(

            <div>
            <div className="body">	
            <div className="navi">
                <div className="part"> PART <b id="id_Part" >&nbsp;</b>.</div>
                <div className="part">TASK <b id="id_Task">&nbsp;.</b></div>
                <div className="part" id="id_PT_Name">&nbsp;</div>
                <div className="remain"> Remaining Time <b id="id_RemainTime" className="time">00:00</b> mins </div>		                
            </div>
            <div className="navi_panel" id="id_navi">
                <div className="navi_part">PART 1</div>
                <div className="navi_task">TASK A</div>
                <ul className="navi_num" id="id_1_A">                   
                    
                </ul>
                
                <div className="navi_task">TASK B</div>
                <ul className="navi_num" id="id_1_B">
                    {/*
                <li>1</li><li>2</li><li>3</li><li>4</li>
                <li>1</li><li>2</li><li>3</li><li>4</li><li>5</li><li>6</li>
                <li>1</li><li>2</li><li>3</li><li>4</li><li>5</li><li>6</li><li>7</li><li>8</li><li>9</li><li>10</li><li>11</li>
                <li>1</li><li>2</li><li>3</li><li>4</li><li>5</li><li>6</li><li>7</li><li>8</li>
                    */}
                </ul>
                <div className="navi_part">PART 2</div>
                <div className="navi_task">TASK A</div>
                <ul className="navi_num" id="id_2_A">
                
                </ul>
                <div className="navi_task">TASK B</div>
                <ul className="navi_num" id="id_2_B">
                
                </ul>
                <div className="navi_task">TASK C</div>
                <ul className="navi_num" id="id_2_C">
                <li>1</li><li>2</li>
                </ul>
            </div>
        
            <div id="id_frame" className="frame_box" style={{border : '2',solid:'red'}}>		
            <div className="queston_Frame" frameBorder="0">
                
                        { 
                            this.state._QImage.indexOf("default") > -1 ? (
                            <Qtype2 qurl={this.state._QUrl} questions={this.props.questions} />                                
                        ) :  this.state._QueTips !="" ? (
                            <Qtype1 qurl={this.state._QUrl} questions={this.props.questions} />                                
                        ) :  this.state.questions !="" ? (
                                <Qtype1 qurl={this.state._QUrl} questions={this.props.questions} />                                
                        ) : (
                            <Qtype1 qurl={this.state._QUrl} questions={this.props.questions} />  
                        )
                     }

                        
            </div>
            </div>
            <div className="control_panel">
                
                
                {/*
                <div className="qvolume">
                    <div id="id_Slider_Volume" style={{volSlideCtrlStyle}} onclick="event.cancelBubble = true;">
                        
                    <iframe id="id_VolumeCtrl" style={{volCtrlStyle}} src="VolumeCtrl.htm" frameborder="0"></iframe>
                    </div>
                        
                    <div className="qequalizer">
                        
                        <OBJECT id="id_MediaControl" border="0" classNameid="clsid:6C1EB50A-7B32-40A1-940E-96E9856F8DAC" style="position:absolute; left:3px; top:0px; width: 143px; height: 45px; z-index: 998">
                        </OBJECT>
                
                    </div>
                
                <div className="qvolume_btn"> <img src="../images/btn_volume_normal.jpg" onload="InitButtion(this, true);" onclick="OnVolumeSliderShow();" alt="" /> </div>
                    
                </div>                
                */}       
                {/*dispAudioVisual*/}
                <div>
                    <ReactMicCom  width={185} GoNext={this.GoNext} ref="child" disptype={'Q'} filename={this.rndSoundFile()}/>
                    {/*<RecordRTCTest  ref="child" disptype="Q" filename={this.rndSoundFile()} pclass={this} showVisualize={this.showVisualize}/>*/}
                        {/*
                        <ReactSWFCompat
                    container={<div style={{background: '#cccccc'}} />}
                    src="../assets/flash/kalcc_record.swf"
                    id="kau_record"
                    width="225"
                    height="155"
                    wmode="transparent"
                    flashVars={{foo: 'A', bar: 1}}
                    />
                        */}
                    </div>
                <div className="studying line hide1"> 
                    {/* .disabled 가 추가되면 해당 컨텐츠는 비활성화 됩니다. */}
                    {/*<!-- .hide 가 추가되면 화면에서 보이지 않습니다. --> */}
                    <img src="../images/title_1.gif" className="title" atl="" />
                    <div className="progress_box">
                    <div id="id_ProgressStudy" className="progress"  style={{backgroundposition: '-119px'}}> 
                            {/*<!-- -119px > 0% , 0px > 100% 입니다. -->*/}
                            <div className="progress_cover"></div>
                        </div>
                    </div>
                    <div className="time_box"><span id="id_StudyTime">&nbsp;</span></div>
                    <div className="clear"></div>
                </div>
                        
                <div className="listening line"> 
                    {/*<!-- .disabled 가 추가되면 해당 컨텐츠는 비활성화 됩니다. --> */}
                    <img id="id_listen_title" src="../images/title_2.gif" className="title" alt=""/>
                    <div className="progress_box">
                        <div id="id_ProgressListen" className="progress" style={{backgroundposition: '-119px'}}> 
                            {/*<!-- -119px > 0% , 0px > 100% 입니다. -->*/}
                            <div className="progress_cover"></div>
                        </div>
                    </div>
                    <div className="time_box"><span id="id_PlayTime">&nbsp;</span></div>
                    <div className="clear"></div>
                    
                    <div className="quiz_btn_box sayagain">
                         <img src="../images/btn_sayagain_bg.png" style={{cursor: 'default',display:'none'}} alt=""/>
                         <img id="id_SayAgain" src="../Images/btn_sayagain_normal.png" onClick={this.SayAgain} onload="InitButtion(this, false)" style={{cursor: 'default', left:'0px', top : '30px', filter: 'alpha(opacity=50)'}} alt=""/>
                         </div>
                </div>
               
                <div className="response line disabled">                     
                    {/*<!-- .disabled 가 추가되면 해당 컨텐츠는 비활성화 됩니다. --> */}
                    <img src="../Images/title_3.gif" className="title" alt=""/>
                    <div className="progress_box">
                        <div id="id_ProgressRec" className="progress" style={{backgroundposition: '-119px'}}> 
                            {/*<!-- -119px > 0% , 0px > 100% 입니다. -->*/}
                            <div className="progress_cover"></div>
                        </div>
                    </div>
                    <div className="time_box"><span id="id_RecTime">&nbsp;</span></div>
                    <div className="clear"></div>                    
                    <div className="recoding_bg">
                        <div className="recoding"> <span>Ready</span> 
                            {/*<!-- 2018.07.30 수정 // -->*/}
                            <div className="active"> <img src="../images/recoding_blink.gif" alt=""/>
                                <div className="text">Recording…</div>
                            </div>
                        </div>
                        <div id="id_RecLow" className="alert">Speaking volume is too low</div>
                    </div>
                    
                   
                    {/*
                    <button onClick={this.sendToRecordStop}>Record Stop</button>
                    <button onClick={this.sendToRecordStart}>Record Start</button>
                    */}
                    <div className="quzi_btn_box next">
                        {/*<Link to={"/test/"+ this.state._sno + "/"+ _NextNo}> <img id="id_GoNext"  onClick={this.GoNext} src="../images/btn_next_normal.png" onload="InitButtion(this, false);" style={{cursor: 'default'}} alt=""/></Link> */}
                        <img id="id_GoNext" src="../images/btn_next_normal.png"  onload="InitButtion(this, false)"  style={{cursor: 'default'}} alt=""/>
                    </div>
                    <div className="bottom_line"></div>
                    
                </div>                
                <div className="msg_warning"><img id="id_msg_warning" style={{margin:'0 auto', visibility:'hidden'}} alt=""/></div>
            </div>
           
            </div>
            </div>
        )
    };
}
export default Questions;