import React from 'react';
import { Link } from 'react-router-dom';


const idStep3 = (props) => {
    const webtoon = props.webtoon;
    return (
        <div className="body">
        <div className="navi2"></div>
        <div className="contents_guide1"> 
          
            <div className="volume">
                <div className="volume_btn"> <img src="./images/btn_volume_normal.png" onclick="OnVolumeSliderShow();" onload="InitButtion(this, true);"/> </div>
                <div id="id_Slider_Volume" style={{left:'-49px', top:'47px', width: '51px', height:'134px', position:'absolute', display: 'none', zindex:'999', background : '../QuestionHtml/Images/volume_control_bg.gif'}} onclick="event.cancelBubble = true;">
                    <iframe id="id_VolumeCtrl" style={{margintop:'9px',width:'100%',height:'124px'}} src="../QuestionHtml/VolumeCtrl.htm" frameborder="0"></iframe>
                </div>
            </div>          
            <div className="text_box">
                <div className="title">Identification – Step 3</div>
                <div className="seat" id="id_SeatNo">&nbsp;</div>
                <div className="user_info">
                    <dl>
                        <dt>Location</dt>
                        <dd id="id_Location">&nbsp;서울시험장</dd>
                        <dt>Type</dt>
                        <dd id="id_ExamType">&nbsp;조종/Pilot</dd>
                        <dt>Subject </dt>
                        <dd id="id_ExamSubject">&nbsp;조종/Pilot</dd>
                        <dt>Application No. </dt>
                        <dd id="id_UserAppNo">&nbsp;180110 01</dd>
                        <dt>Name </dt>
                        <dd id="id_UserName">&nbsp;홍길동</dd>
                        <dt>D.O.B </dt>
                        <dd id="id_UserBirth">&nbsp;1999.12.12</dd>
                    </dl>
                </div>
            </div>
            <div className="btn_box">
                <ul>
                    <li className="second">Next page in <span className="color_re" id="id_Second">&nbsp;</span> Seconds</li>
                    <li><Link to="/ready/5"><img src="../Images/btn_confirm_normal.png" onload="InitButtion(this, true);" onclick="GoNext();"/></Link></li>
                </ul>
            </div>
        </div>
    </div>
    )
}

export default idStep3;